import React, { PureComponent, Fragment } from 'react';
import './css/GridCard.css';
import PropTypes from 'prop-types';
import ProgressBar from './ProgressBar';
import ReplacementImage from '../../../logo/xemelgo-logo.jpg';
// ReplacementImage used by OnError() in case of errant image url from prop data

const healthColorMap = {
  'out of stock': '#F74446',
  'low stock': '#F5B052',
  good: '#61D438',
  overstock: '#4298FA'
};

/*
 * GridCard takes props from GridView, sets state, renders object image + name,
 * and passes data down to ProgressBar
 */
export default class GridCard extends PureComponent {
  constructor(props) {
    super(props);
    const {
      name,
      lowStockThreshold,
      overstockThreshold,
      quantity,
      objectTypeId,
      healthStatus,
      imageUrl
    } = this.props;
    const healthColor = healthColorMap[healthStatus];

    this.state = {
      src: imageUrl === null ? ReplacementImage : imageUrl,
      errored: false,
      objectTypeId,
      name,
      lowStockThreshold,
      overstockThreshold,
      quantity,
      healthColor
    };
  }

  onError = () => {
    const { errored } = this.state;
    if (!errored) {
      this.setState({
        src: ReplacementImage,
        errored: true
      });
    }
  };

  render() {
    const {
      name,
      lowStockThreshold,
      overstockThreshold,
      quantity,
      healthColor,
      objectTypeId,
      src
    } = this.state;
    const borderColor = { borderTop: `15px solid ${healthColor}` };

    return (
      <Fragment>
        <a
          style={borderColor}
          className="card-container"
          href={`tracked-object/details?type=part&item=${objectTypeId}`}
        >
          <img className="card-image" src={src} alt="Failed to load" onError={this.onError} />
          <p className="name">{`${name}`}</p>
          <ProgressBar
            min={lowStockThreshold}
            max={overstockThreshold}
            value={quantity}
            healthColor={healthColor}
          />
        </a>
      </Fragment>
    );
  }
}

GridCard.propTypes = {
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  lowStockThreshold: PropTypes.number.isRequired,
  overstockThreshold: PropTypes.number,
  quantity: PropTypes.number.isRequired,
  healthStatus: PropTypes.string.isRequired,
  objectTypeId: PropTypes.string.isRequired
};

GridCard.defaultProps = {
  imageUrl: '',
  overstockThreshold: null
};
