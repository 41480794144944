import React, { PureComponent, Fragment } from 'react';
import { Card, CardBody } from 'mdbreact';
import TrackService from '../../../services/TrackService';
import Chart from './Chart';
import Overview from './Overview';
import GridView from './GridView';
import './css/InventoryComponent.css';
import RestService from '../../../services/RestService';

const HealthMap = {
  Critical: '#F74446',
  Warning: '#F5b052',
  Healthy: '#61D438'
};

/*
 * InventoryComponent is the core component for Inventory Track Page. Initiates default state,
 * calls APIs for real data, and passes data down to appropriate child components.
 * Child components are Chart (top left), Overview (top right), and GridView (bottom half)
 */
export default class InventoryComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      plant: '',
      location: '',
      objects: [],
      health: 'Loading...',
      color: 'black',
      totalUnits: 0,
      totalItems: 0,
      consumptionRate: 0,
      unitsAdded: 0,
      unitsConsumed: 0
    };
  }

  async componentDidMount() {
    const trackService = await TrackService.build();
    const objects = trackService.getTrackDataList();
    const overview = trackService.getOverviewData();
    const weekly = trackService.getWeeklyStatus();
    const plant = await RestService.get('/plants');
    const department = await RestService.get('/departments');
    const status = HealthMap[overview.health] || 'black';
    this.setState({
      plant: plant[0] ? plant[0].plant_name : '',
      location: department[0] ? ` - ${department[0].department_name}` : '',
      objects,
      health: overview.health,
      color: status,
      totalUnits: overview.total_units,
      totalItems: overview.total_items,
      consumptionRate: weekly.rate_per_day,
      unitsAdded: weekly.add,
      unitsConsumed: weekly.consume,
      data: [
        ['Status', 'Items'],
        ['Out of Stock', overview.out_of_stock],
        ['Low Stock', overview.low_stock],
        ['Overstock', overview.overstock],
        ['Good', overview.good]
      ]
    });
  }

  render() {
    const {
      plant,
      location,
      objects,
      health,
      color,
      totalUnits,
      totalItems,
      consumptionRate,
      unitsAdded,
      unitsConsumed,
      data
    } = this.state;

    return (
      <Fragment>
        <Card className="content_holder">
          <CardBody className="override-padding">
            <div className="chart">
              <Chart plant={plant} location={location} data={data || [['', ''], ['', '']]} />
            </div>
            <div className="overview">
              <Overview
                health={health}
                color={color}
                totalUnits={totalUnits}
                totalItems={totalItems}
                consumptionRate={consumptionRate}
                unitsAdded={unitsAdded}
                unitsConsumed={unitsConsumed}
              />
            </div>
            <hr className="line" />
            <div className="grid-view">
              <GridView objects={objects} />
            </div>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}
