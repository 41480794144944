import React, { Component, Fragment } from 'react';

import ConfigurationService from '../../services/ConfigurationService';
import OrderReports from './OrderBackLog';
import InventoryReports from './InventoryReports';

export default class ReportsPage extends Component {
  render() {
    const solutionType = ConfigurationService.getSolutionType();
    return (
      <Fragment>{solutionType === 'order' ? <OrderReports /> : <InventoryReports />}</Fragment>
    );
  }
}
