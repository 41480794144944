import React, { Component } from 'react';
import { Modal, Alert } from 'react-bootstrap';
import { Form } from 'reactstrap';

// TEMPORARY FIX, NEED TO ABSTRACT OUT CSS NEEDED FROM THAT FILE AS IT IS NO LONGER USED
import '../pages/order/TrackOrderPage.css';

import _ from 'lodash';

export default class EditModalMultipleForm extends Component {
  constructor(props) {
    super(props);

    this.updateProviderErrors = this.updateProviderErrors.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.imageValues = this.imageValues.bind(this);

    this.state = {
      stagedItems: [_.cloneDeep(this.props.provider.getInputSchema())],
      inputValidation: [],
      submitting: false
    };
  }

  changeValues(event, value, index) {
    let stagedItems = _.cloneDeep(this.state.stagedItems);
    stagedItems[index][value] = event.target.value;

    this.setState({
      stagedItems: stagedItems
    });
  }

  imageValues(event, value, index) {
    let stagedItems = _.cloneDeep(this.state.stagedItems);
    stagedItems[index][value] = event.target.files[0];

    this.setState({
      stagedItems: stagedItems
    });
  }

  updateProviderErrors(successRegistry, successCollection, errorCollection) {
    this.setState({
      successRegistry: successRegistry,
      successCollection: successCollection,
      errorCollection: errorCollection
    });
  }

  showErrorMessage() {
    this.setState({
      showErrorMessage: true,
      submitting: false
    });
  }

  async handleSubmit() {
    if (this.state.stagedItems[0]['objectTypeName'] || this.state.stagedItems[0]['imageDetails']) {
      let stagedItems = _.cloneDeep(this.state.stagedItems);
      stagedItems[0]['objectTypeId'] = this.props.placeholder['objectTypeId'];
      await this.setState({
        stagedItems: stagedItems,
        errorCollection: [],
        showErrorMessage: false,
        showTransactionReport: false,
        submitting: true
      });
      await this.props.provider.editItem(this.updateProviderErrors, this.state.stagedItems);
    }
    this.props.handleClose();
  }

  render() {
    let submissionErrorMessage = type => {
      return (
        <Alert
          className="submission-report-alerts"
          bsStyle="danger"
          onDismiss={() => this.setState({ showErrorMessage: false })}
        >
          <h4>Error </h4>
          <p>Action was not completed. Please review highlighted items and try again.</p>
        </Alert>
      );
    };

    let addTransactionReport = () => {
      return (
        <Alert
          className="submission-report-alerts"
          bsStyle="danger"
          onDismiss={() => this.setState({ showTransactionReport: false })}
        >
          <h4>Submission Error</h4>
          <p>
            [{this.state.errorCollection.length}] out of [
            {this.state.errorCollection.length + this.state.successCollection.length}]
            {this.state.errorCollection.length + this.state.successCollection.length > 1
              ? ' submissions'
              : ' submission'}{' '}
            has failed. Affected {this.state.errorCollection.length > 1 ? 'values ' : 'value '}{' '}
            {this.state.errorCollection.length > 1 ? 'are ' : 'is '} as follows:
          </p>
          <table>
            <tbody>
              {this.state.errorCollection.map((error, index) => {
                return (
                  <tr key={index}>
                    <td>{error.entry + ' - ' + error.errMessage}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Alert>
      );
    };

    return (
      <Modal show={true} onHide={this.props.handleClose} backdrop="static">
        <Modal.Header className="route-modal-header" closeButton>
          <Modal.Title className="route-modal-title">
            {'Edit Item ID: ' + this.props.placeholder['objectTypeId']}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.showTransactionReport && addTransactionReport()}
          {this.state.showErrorMessage && submissionErrorMessage()}
          {this.state.stagedItems.map((value, index) => {
            return (
              <Form key={index}>
                {this.props.provider.itemsSchema(
                  index,
                  this.changeValues,
                  this.state.inputValidation[index],
                  this.imageValues,
                  false,
                  this.props.placeholder
                )}
              </Form>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <div id="footer-container">
            <div id="footer-buttons">
              <button className="cancel-button" onClick={this.props.handleClose}>
                Cancel
              </button>
              <button
                className="default-button"
                disabled={this.state.submitting}
                onClick={() => this.handleSubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
