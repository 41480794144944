import React, { Component, Fragment } from 'react';
import { Card } from 'mdbreact';

import DetailsCard from 'components/DetailsCard';
import AnalysisService from 'services/AnalysisService';
import DataTableComponent from 'components/DataTableComponent/DataTableComponent';
import { getCustomerLogo } from 'common/Utilities';
import Moment from 'react-moment';
import ConfigurationService from '../../services/ConfigurationService';
import TrackObjectNotFoundPage from '../error/TrackObjectNotFoundPage';

export default class PartDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: this.props.search,
      service: undefined,
      loading: true,
      validObject: true,
      doneFetching: false,
      overallInformation: [],
      metaInformation: null,
      image_info: undefined,
      columnDefs: [
        {
          headerName: 'Department Name',
          field: 'department_name',
          filter: 'agTextColumnFilter',
          filterParams: {
            clearButton: true
          }
        },
        {
          headerName: 'Quantity',
          field: 'count',
          filter: 'agTextColumnFilter',
          filterParams: {
            clearButton: true
          }
        }
      ],
      gridOptions: {
        defaultColDef: {
          comparator(a, b) {
            if (typeof a === 'string') {
              return a.localeCompare(b);
            }
            return a > b ? 1 : a < b ? -1 : 0;
          }
        }
      }
    };

    this.updateData = this.updateData.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.search !== this.state.searchString) {
      this.setState({
        searchString: newProps.search,
        doneFetching: false,
        metaInformation: null
      });

      this.updateData(newProps.search);
    }
  }

  UNSAFE_componentWillMount() {
    this.updateData();
  }

  async updateData(searchString) {
    const query = searchString || this.state.searchString;
    await AnalysisService.build(query).then(
      async build => {
        const objectType = await build.getObjectTypeById(query);
        const objectList = build.getArray();
        const qty = objectList.length;
        const lastUpdatedTime = await build.getLastUpdatedTime();
        const locations = build.getAggregateLocations();

        let imageInfo = getCustomerLogo();
        if (objectType.image) {
          const customerConfig = ConfigurationService.getFullConfigurations();
          imageInfo = `${customerConfig.s3.URL}/${objectType.image.image_url}`;
        }

        this.setState({
          service: build,
          objectTypeName: objectType.object_type_name,
          objectQty: qty,
          lastUpdated: lastUpdatedTime,
          locationList: locations,
          doneFetching: true,
          image_info: imageInfo
        });
      },
      err => {
        this.setState({
          error: {
            status: err.response.data.errorType,
            message: err.response.data.message
          },
          doneFetching: true,
          validObject: false
        });
      }
    );
  }

  buildData() {
    this.setState({
      overallInformation: [
        {
          name: 'Quantity:',
          action: this.state.objectQty + (this.state.objectQty !== 1 ? ' units' : ' unit')
        },
        {
          name: 'Last Updated:',
          action: this.getLastSeenTime()
        }
      ],
      metaInformation: {
        display_name: 'Item Name:',
        display_value: this.state.objectTypeName,
        img: this.state.image_info
      }
    });
  }

  getLastSeenTime() {
    if (this.state.lastUpdated) {
      return (
        <Fragment>
          <div>
            <Moment format="hh:mm A">{this.state.lastUpdated}</Moment>
          </div>
          <div>
            <Moment format="MMM DD, YYYY">{this.state.lastUpdated}</Moment>
          </div>
        </Fragment>
      );
    }
    return 'Not Detected';
  }

  render() {
    if (this.state.doneFetching) {
      if (!this.state.validObject) {
        return <TrackObjectNotFoundPage error={this.state.error} />;
      }
      if (!this.state.metaInformation) {
        this.buildData();
      }
    }

    return (
      <div>
        {
          <DetailsCard
            overallInformation={this.state.overallInformation}
            metaInformation={this.state.metaInformation}
          />
        }
        <div className="location-table-title">
          <h2>Location</h2>
        </div>
        <Card className="location-table">
          {
            <DataTableComponent
              columnDefs={this.state.columnDefs}
              rowData={this.state.service ? this.state.locationList : null}
              gridOptions={this.state.gridOptions}
            />
          }
        </Card>
      </div>
    );
  }
}
