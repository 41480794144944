import React, { Component, Fragment } from 'react';
import { CardBody } from 'mdbreact';
import { Modal, Alert } from 'react-bootstrap';
import { Form, NavLink } from 'reactstrap';
import _ from 'lodash';
import RestService from '../../services/RestService';
import CollapsibleListView from 'components/CollapsibleListView';
import './ManageInfrastructure.css';
import Loading from 'components/loading/LoadingWord';
import DepartmentResource from './resources/DepartmentResource';
import PlantResource from './resources/PlantResource';
import GatewayResource from './resources/GatewayResource';
import ZoneResource from './resources/ZoneResource';

const infoSchema = [
  { name: '', parentId: '', description: '', optionalGatewayNumber: '', imageDetails: '' }
];

export default class ManageOrganizationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeButton: 'listView',
      tabKey: 1,
      showDeleteModal: false,
      showEditModal: false,
      stagedItems: _.cloneDeep(infoSchema),
      nameCheck: [],
      parentIdCheck: [],
      submitting: false
    };

    this.addHandler = this.addHandler.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.editHandler = this.editHandler.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.showErrorMessage = this.showErrorMessage.bind(this);
    this.formValidationChecks = this.formValidationChecks.bind(this);
    this.updateProviderErrors = this.updateProviderErrors.bind(this);
    this.imageValues = this.imageValues.bind(this);
  }

  async componentDidMount() {
    let getCustomerInfo = async () => {
      let customers = await RestService.get('/customers');

      let customerList = _.map(customers, customer => {
        let customerId = customer.customer_id;

        return {
          customerId: customerId
        };
      });

      return customerList;
    };

    let customer = await getCustomerInfo();
    let providers = [
      await PlantResource.build(),
      await DepartmentResource.build(),
      await ZoneResource.build(),
      await GatewayResource.build()
    ];

    this.setState({
      resourceProviders: providers,
      customerId: customer[0].customerId
    });
  }

  handleTab(key, name) {
    if (this.state.tabKey !== key) {
      this.setState({
        tabKey: key,
        activeButton: name
      });
    }
  }

  editHandler(itemId, resourceName) {
    let resourceProvider = this.state.resourceProviders.find(
      provider => provider.getName() === resourceName
    );
    let arrayItem = resourceProvider.getArrayItemById(itemId);

    this.setState({
      showEditModal: true,
      editProvider: resourceProvider,
      itemToEdit: arrayItem
    });
  }

  deleteHandler(itemId, resourceName) {
    let resourceProvider = this.state.resourceProviders.find(
      provider => provider.getName() === resourceName
    );
    let arrayItem = resourceProvider.getArrayItemById(itemId);

    this.setState({
      showDeleteModal: true,
      deleteProvider: resourceProvider,
      itemToDelete: arrayItem
    });
  }

  addHandler(resourceName) {
    let resourceProvider = this.state.resourceProviders.find(
      provider => provider.getName() === resourceName
    );

    this.setState({
      showAddModal: true,
      addProvider: resourceProvider
    });
  }

  handleModalClose() {
    this.setState({
      showEditModal: false,
      showDeleteModal: false,
      showAddModal: false,
      showErrorMessage: false,
      showTransactionReport: false,
      nameCheck: [],
      parentIdCheck: [],
      stagedItems: _.cloneDeep(infoSchema),
      errorCollection: [],
      submitting: false
    });
  }

  showErrorMessage() {
    this.setState({
      showErrorMessage: true,
      submitting: false
    });
  }

  removeItemAtIndex(index) {
    this.setState({
      stagedItems: this.state.stagedItems.filter((_, i) => i !== index),
      nameCheck: this.state.nameCheck.filter((_, i) => i !== index),
      parentIdCheck: this.state.parentIdCheck.filter((_, i) => i !== index)
    });
  }

  changeValues(event, stateVariable, index) {
    let stagedItems = [...this.state.stagedItems];
    stagedItems[index][stateVariable] = event.target.value;

    this.setState({
      stagedItems: stagedItems
    });
  }

  imageValues(event, stateVariable, index) {
    let stagedItems = [...this.state.stagedItems];
    stagedItems[index][stateVariable] = event.target.files[0];

    this.setState({
      stagedItems: stagedItems
    });
  }

  formValidationChecks(formName) {
    let checkArray = [];
    let fieldsPass = true;

    this.state.stagedItems.forEach((item, index) => {
      checkArray[index] = item[formName] === '';

      if (checkArray[index]) {
        fieldsPass = false;
      }
    });

    this.setState({
      [formName + 'Check']: checkArray
    });

    return fieldsPass;
  }

  updateProviderErrors(successRegistry, successCollection, errorCollection) {
    this.setState({
      successRegistry: successRegistry,
      successCollection: successCollection,
      errorCollection: errorCollection
    });
  }

  async handleSubmit(type) {
    this.setState({
      errorCollection: [],
      showErrorMessage: false,
      showTransactionReport: false,
      submitting: true
    });

    if (type === 'add') {
      if (
        !(await this.state.addProvider.addItem(
          this.formValidationChecks,
          this.updateProviderErrors,
          this.state.stagedItems,
          this.state.resourceProviders,
          this.state.customerId
        ))
      ) {
        this.showErrorMessage();
        return;
      }
    } else if (type === 'edit') {
      if (
        !(await this.state.editProvider.editItem(
          this.formValidationChecks,
          this.updateProviderErrors,
          this.state.stagedItems,
          this.state.resourceProviders,
          this.state.customerId,
          this.state.itemToEdit
        ))
      ) {
        this.showErrorMessage();
        return;
      }
    }

    if (this.state.errorCollection.length !== 0) {
      while (this.state.successRegistry.length !== 0) {
        this.removeItemAtIndex(this.state.successRegistry.pop());
      }

      this.setState({
        showTransactionReport: true,
        submitting: false
      });
    } else {
      this.handleModalClose();
    }
  }

  render() {
    let editModal = () => {
      return (
        <Modal show={this.state.showEditModal} onHide={this.handleModalClose} backdrop="static">
          <Modal.Header className="route-modal-header" closeButton>
            <Modal.Title className="route-modal-title">
              Editing {this.state.itemToEdit.thisName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.showTransactionReport && addTransactionReport()}
            {this.state.showErrorMessage && submissionErrorMessage('Add')}
            {this.state.stagedItems.map((item, index) => {
              return (
                <Form key={index}>
                  {index !== 0 && <hr className="modal-break-tag" />}
                  {this.state.editProvider.editItemsSchema(
                    index,
                    this.state.itemToEdit,
                    this.changeValues,
                    this.state.resourceProviders,
                    this.state.nameCheck,
                    this.state.parentIdCheck
                  )}
                </Form>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <div id="footer-container">
              <div id="footer-buttons">
                <button className="cancel-button" onClick={this.handleModalClose}>
                  Cancel
                </button>
                <button
                  className="default-button"
                  disabled={this.state.submitting}
                  onClick={() => this.handleSubmit('edit')}
                >
                  Save
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      );
    };

    let deleteModal = () => {
      return (
        <Modal show={this.state.showDeleteModal} onHide={this.handleModalClose}>
          <Modal.Header className="route-modal-header" closeButton>
            <Modal.Title className="route-modal-title">
              Delete {this.state.itemToDelete.thisName}?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.showErrorMessage && submissionErrorMessage('Delete')}
            <p>
              You're about to delete {this.state.itemToDelete.thisName}, are you sure you want to do
              this?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button className="cancel-button" onClick={this.handleModalClose}>
              Cancel
            </button>
            <button
              className="confirm-delete-button"
              onClick={() =>
                this.state.deleteProvider.deleteItem(
                  this.state.itemToDelete,
                  this.handleModalClose,
                  this.showErrorMessage
                )
              }
            >
              Confirm Deletion
            </button>
          </Modal.Footer>
        </Modal>
      );
    };

    let addTransactionReport = () => {
      return (
        <Alert
          className="submission-report-alerts"
          bsStyle="danger"
          onDismiss={() => this.setState({ showTransactionReport: false })}
        >
          <h4>Submission Error</h4>
          <p>
            [{this.state.errorCollection.length}] out of [
            {this.state.errorCollection.length + this.state.successCollection.length}]
            {this.state.errorCollection.length + this.state.successCollection.length > 1
              ? ' submissions'
              : ' submission'}{' '}
            has failed. Affected {this.state.errorCollection.length > 1 ? 'values ' : 'value '}{' '}
            {this.state.errorCollection.length > 1 ? 'are ' : 'is '} as follows:
          </p>
          <table>
            <tbody>
              {this.state.errorCollection.map((error, index) => {
                return (
                  <tr key={index}>
                    <td>{error.entry + ' - ' + error.errMessage}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Alert>
      );
    };

    let submissionErrorMessage = type => {
      return (
        <Alert
          className="submission-report-alerts"
          bsStyle="danger"
          onDismiss={() => this.setState({ showErrorMessage: false, errorCollection: [] })}
        >
          <h4>Error </h4>
          <p>
            Action was not completed.{' '}
            {type === 'Add' ? ' Please review highlighted items ' : ' Check for any dependencies '}{' '}
            and try again.
          </p>
        </Alert>
      );
    };

    let addModal = () => {
      return (
        <Modal show={this.state.showAddModal} onHide={this.handleModalClose} backdrop="static">
          <Modal.Header className="route-modal-header" closeButton>
            <Modal.Title className="route-modal-title">
              {'Add ' + this.state.addProvider.getName()}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.showTransactionReport && addTransactionReport()}
            {this.state.showErrorMessage && submissionErrorMessage('Add')}
            {this.state.stagedItems.map((item, index) => {
              return (
                <Form key={index}>
                  {index !== 0 && <hr className="modal-break-tag" />}
                  {this.state.addProvider.addItemsSchema(
                    index,
                    item,
                    this.changeValues,
                    this.state.resourceProviders,
                    this.state.nameCheck,
                    this.state.parentIdCheck,
                    this.imageValues
                  )}
                </Form>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <div id="footer-container">
              <div id="footer-actions">
                <div id="modal-remove-container">
                  {this.state.stagedItems.length > 1 && (
                    <NavLink
                      id="level-modal-remove"
                      onClick={() => this.removeItemAtIndex(this.state.stagedItems.length - 1)}
                    >
                      {'- Remove last'}
                    </NavLink>
                  )}
                </div>
                <NavLink
                  id="level-modal-add"
                  onClick={() =>
                    this.setState({
                      stagedItems: this.state.stagedItems.concat(_.cloneDeep(infoSchema))
                    })
                  }
                >
                  {'+ Add another'}
                </NavLink>
              </div>
              <div id="footer-buttons">
                <button className="cancel-button" onClick={this.handleModalClose}>
                  Cancel
                </button>
                <button
                  className="default-button"
                  disabled={this.state.submitting}
                  onClick={() => this.handleSubmit('add')}
                >
                  Submit
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      );
    };

    return (
      <Fragment>
        <CardBody id="main-card-body">
          <div className="title">
            <h2>Manage Plants</h2>
            <div>Set up and edit the structure of your organization</div>
          </div>
          {!this.state.resourceProviders ? (
            <Loading />
          ) : (
            this.state.activeButton === 'listView' &&
            this.state.resourceProviders && (
              <div>
                {this.state.showEditModal && editModal()}
                {this.state.showDeleteModal && deleteModal()}
                {this.state.showAddModal && addModal()}
                {this.state.resourceProviders.map(provider => {
                  return (
                    <CollapsibleListView
                      key={provider.getName()}
                      tierArray={provider.getArray()}
                      headerName={provider.getName()}
                      editHandler={this.editHandler}
                      deleteHandler={this.deleteHandler}
                      addHandler={this.addHandler}
                    />
                  );
                })}
              </div>
            )
          )}
        </CardBody>
      </Fragment>
    );
  }
}

/*
--Placing the button code here for safe keeping until we implement hierarchical view

                        <ButtonToolbar className="buttonbar">
                            <ToggleButtonGroup className="backlog-toggle" type="radio" name="backlogs">
                                <ToggleButton className={this.state.activeButton === 'listView' ? 'active' : ''}
                                              value='listView' onClick={() => this.handleTab(1, 'listView')}
                                              bsClass="tabs">List View</ToggleButton>
                                <ToggleButton className={this.state.activeButton === 'hierarchicalView' ? 'active' : ''}
                                              value='hierarchicalView'
                                              onClick={() => this.handleTab(2, 'hierarchicalView')} bsClass="tabs"
                                              >Hierarchical View</ToggleButton>
                            </ToggleButtonGroup>
                            <button onClick = {() => this.setState({ switchPage: true })} id="change" className="manage-tags-btn">Manage Tags</button>
                        </ButtonToolbar>
 */
