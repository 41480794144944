import React, { PureComponent, Fragment } from 'react';
import { FormControl } from 'react-bootstrap';
import {
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortAmountDownAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'mdbreact';
import RestService from '../../../services/RestService';
import GridCard from './GridCard';
import SideFilter from './SideFilter';
import Style from './css/GridView.module.css';

const HealthMap = {
  critical: '#FF3D46',
  warning: '#F6B045',
  normal: '#62D32E'
};

const FilterIcon = {
  ascending: {
    filterFunc: (objects, attribute) => {
      return objects.sort((a, b) => {
        if (attribute === 'received_timestamp') {
          return sortByAttribute(
            a[attribute] || a['published_timestamp'],
            b[attribute] || b['published_timestamp']
          );
        }
        return sortByAttribute(a[attribute], b[attribute]);
      });
    }
  },
  descending: {
    filterFunc: (objects, attribute) => {
      return objects.sort((a, b) => {
        if (attribute === 'received_timestamp') {
          return sortByAttribute(
            b[attribute] || b['published_timestamp'],
            a[attribute] || a['published_timestamp']
          );
        }
        return sortByAttribute(b[attribute], a[attribute]);
      });
    }
  }
};

let sortByAttribute = (x, y) => {
  if (x && !y) {
    return 1;
  }
  if (!x && y) {
    return -1;
  }
  return String(x).localeCompare(String(y));
};

export default class GridView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: '',
      placeholder: 'Search',
      objects: [],
      selectedFilter: null,
      filteredData: null,
      defaultData: null,
      loading: true,
      filter: null,
      healthColorMap: null,
      currentSort: {
        object_name: 'ascending',
        due_date: 'ascending',
        received_timestamp: 'ascending',
        creation_date: 'ascending'
      },
      attribute: this.props.defaultSorting
    };
  }

  async componentDidMount() {
    const { tagMap, searchObject, objects, filter, placeholderData } = this.props;
    await this.getAllDepartmentThresholds();

    objects.forEach((item, index) => {
      const trackerName = tagMap[item.tracker_id] || 'No Tag';
      return (objects[index] = { ...objects[index], tracker_serial: trackerName });
    });

    if (!filter) {
      this.filterByLocation();
    } else {
      this.getDepartmentSpecificData(objects);
    }

    this.setState({
      selectedFilter: 'All Orders',
      loading: false,
      tagMap,
      searchObject,
      objects,
      filter,
      placeholderData
    });
  }

  getAllDepartmentThresholds = async () => {
    const thresholdMap = {};
    const allDepartments = await RestService.get(`/departments`);
    allDepartments.forEach(dept => {
      thresholdMap[dept.department_name] = dept.thresholds;
    });

    this.setState({ thresholdMap, allDepartments });
  };

  getCurrentThresholdState = (deptName, totalOrderCount) => {
    const { thresholdMap } = this.state;
    let currentThresholdState = 'normal';
    if (thresholdMap && thresholdMap[deptName]) {
      if (totalOrderCount >= thresholdMap[deptName].orderThresholdWarning)
        currentThresholdState = 'warning';
      if (totalOrderCount >= thresholdMap[deptName].orderThresholdCritical)
        currentThresholdState = 'critical';
    }

    return currentThresholdState;
  };

  getDepartmentSpecificData = async objects => {
    const healthColorMap = {};

    const department = objects[0].department;
    const deptName = department.department_name;
    const expeditedOrderCount = this.getExpeditedOrdersCount(objects);
    const totalOrderCount = objects.length;

    const currentThresholdState = this.getCurrentThresholdState(deptName, totalOrderCount);

    healthColorMap[deptName] = HealthMap[currentThresholdState];

    const departmentData = {
      groupInfo: {
        id: department.department_id,
        name: deptName,
        simpleName: this.getSimpleName(deptName),
        currentThresholdState,
        attributes: {
          count: {
            total: {
              name: '# of Orders',
              value: totalOrderCount
            },
            expedited: {
              name: 'Expedited Orders',
              value: expeditedOrderCount
            }
          }
        }
      }
    };

    this.props.callbackFn(departmentData);
  };

  handleSelected = selectedFilter => {
    this.setState({
      selectedFilter,
      filteredData: null
    });
    if (selectedFilter === 'Location') {
      this.setState({ attribute: 'object_name' });
    } else if (selectedFilter === 'All Orders') {
      this.setState({
        loading: false,
        attribute: 'due_date'
      });
    }
  };

  sortAlpha = list => {
    const sortedList = []
      .concat(list)
      .sort((a, b) => (a.groupInfo.name > b.groupInfo.name ? 1 : -1));
    return sortedList;
  };

  splitAndSort = list => {
    const status = [];
    let normal = [];
    for (let i = 0; i < list.length; i++) {
      normal.push(list[i]);
    }
    normal = this.sortAlpha(normal);
    return status.concat(normal);
  };

  filterByLocation = () => {
    const data = this.props.objects;

    let locationData = [];

    locationData = this.filterLocations(data);
    this.setState({
      defaultData: locationData,
      loading: false
    });
  };

  filterLocations = data => {
    const inventoryByLocations = this.sortByGroupInfoName(this.splitByDepartment(data));
    let result;

    result = inventoryByLocations.map(sbt => {
      const items = sbt.items;
      const lastUpdatedTime = this.getLastUpdatedTime(items);

      return {
        groupInfo: sbt.groupInfo,
        items,
        received_timestamp: lastUpdatedTime,
        object_name: sbt.object_name
      };
    });

    return result;
  };

  getSimpleName = fullName => {
    // if has white space
    if (/\s/g.test(fullName)) {
      const matches = fullName.match(/\b(\w)/g); // ['J','S','O','N']
      const acronym = matches.join('');
      return acronym;
    }

    if (fullName.length > 6) {
      return fullName.substring(0, 4);
    }
    return fullName;
  };

  splitByDepartment = data => {
    const { allDepartments } = this.state;
    const deparmentIdMap = {};
    const healthColorMap = {};

    const groupByDepartmentMap = data.reduce((byDepartmentMap, item) => {
      const department = item.department;
      if (!department) {
        return null;
      }

      // group by department id
      const departmentId = department.department_id;
      const list = byDepartmentMap[departmentId] || [];
      list.push(item);

      byDepartmentMap[departmentId] = list;

      // register department
      deparmentIdMap[departmentId] = department;

      return byDepartmentMap;
    }, {});

    let departmentData = Object.keys(groupByDepartmentMap).map(departmentId => {
      const department = deparmentIdMap[departmentId];
      const deptName = department.department_name;
      const items = groupByDepartmentMap[departmentId];
      const expeditedOrderCount = this.getExpeditedOrdersCount(items);
      const totalOrderCount = items.length;

      const currentThresholdState = this.getCurrentThresholdState(deptName, totalOrderCount);

      healthColorMap[department.department_name] = HealthMap[currentThresholdState];

      return {
        groupInfo: {
          id: department.department_id,
          name: department.department_name,
          simpleName: this.getSimpleName(department.department_name),
          currentThresholdState,
          attributes: {
            count: {
              total: {
                name: '# of Orders',
                value: totalOrderCount
              },
              expedited: {
                name: 'Expedited Orders',
                value: expeditedOrderCount
              },
              non_expedited: {
                name: 'Non-Expedited Orders',
                value: totalOrderCount - expeditedOrderCount
              }
            }
          }
        },
        items,
        object_name: department.department_name
      };
    });

    allDepartments.map(department => {
      const departmentId = department.department_id;
      const matchingDepartment = departmentData.find(
        department => department.groupInfo.id === departmentId
      );

      if (!matchingDepartment || departmentData.length === 0) {
        departmentData = [
          ...departmentData,
          {
            groupInfo: {
              id: departmentId,
              name: department.department_name,
              simpleName: this.getSimpleName(department.department_name),
              currentThresholdState: 'normal',
              attributes: {
                count: {
                  total: {
                    name: '# of Orders',
                    value: 0
                  },
                  expedited: {
                    name: 'Expedited Orders',
                    value: 0
                  },
                  non_expedited: {
                    name: 'Non-Expedited Orders',
                    value: 0
                  }
                }
              }
            },
            items: [],
            object_name: department.department_name
          }
        ];
        healthColorMap[department.department_name] = '#62D32E';
      }
      return departmentData; // to fix compiler warning
    });
    this.setState({ healthColorMap });
    this.props.callbackFn(departmentData);
    return departmentData;
  };

  splitOrders = data => {
    return data.map(order => {
      return {
        groupInfosplitByDepartment: {
          id: order.tracked_object_id,
          name: order.object_name,
          order
        },
        items: order,
        object_name: order.object_name
      };
    });
  };

  sortByGroupInfoName = list => {
    const sortedList = this.splitAndSort(list);

    return sortedList;
  };

  getExpeditedOrdersCount(items) {
    let count = 0;
    items.forEach(item => {
      if (item.is_expedited) {
        count++;
      }
    });
    return count;
  }

  getLastUpdatedTime(items) {
    const times = [];
    items.forEach(item => {
      const time = item.received_timestamp || item.published_timestamp;
      times.push(new Date(time));
    });

    return times.length > 0 ? Math.max.apply(null, times) : undefined;
  }

  getPlaceholder() {
    const { placeholderData } = this.props || null;
    let placeholder = 'Filter Items';

    if (placeholderData) {
      if (placeholderData.deptName) {
        placeholder = `Search for Orders in ${placeholderData.deptName} Department`;
      } else if (placeholderData.plantName) {
        placeholder = `Search Departments in ${placeholderData.plantName}`;
      }
    }

    return placeholder;
  }

  filterBar = placeholder => {
    const { filterValue } = this.state;
    return (
      <div className={Style.filter_group}>
        <a className="btn default-button add" href="/tracked-object/add">
          + Add Order
        </a>
        <FormControl
          type="text"
          className={Style.grid_search_bar}
          value={filterValue}
          placeholder={placeholder}
          onChange={e => {
            this.setState({ filterValue: e.target.value });
          }}
        />
        {this.sortButton()}
      </div>
    );
  };

  sortButton = () => {
    const { currentSort, attribute, defaultData, selectedFilter } = this.state;
    const config = this.props.config.preference;
    const option =
      config && (!defaultData || selectedFilter === 'All Orders') ? config.PRIMARY : 'Name';

    const sortSchema = [
      {
        name: option,
        attribute: 'object_name',
        icon: currentSort.object_name === 'ascending' ? faSortAlphaDown : faSortAlphaUp
      },
      {
        name: 'Last Updated',
        attribute: 'received_timestamp',
        icon: currentSort.received_timestamp === 'ascending' ? faSortNumericDown : faSortNumericUp
      }
    ];
    if (!defaultData || selectedFilter === 'All Orders') {
      sortSchema.push({
        name: 'Date Created',
        attribute: 'creation_date',
        icon: currentSort.creation_date === 'ascending' ? faSortNumericDown : faSortNumericUp
      });
      sortSchema.unshift({
        name: 'Due Date',
        attribute: 'due_date',
        icon: currentSort.due_date === 'ascending' ? faSortNumericDown : faSortNumericUp
      });
    }
    return (
      <Dropdown>
        <DropdownToggle nav caret id="custom-nav">
          <FontAwesomeIcon icon={faSortAmountDownAlt} size="lg" className="filter-icon" />
        </DropdownToggle>
        <DropdownMenu className="dropdownmenu">
          {sortSchema.map(each => {
            return (
              <DropdownItem
                onClick={() => {
                  this.sortClick(each.attribute);
                }}
                active={each.attribute === attribute}
                toggle={false}
                style={{ outline: 'none' }}
              >
                {`${each.name}  `}
                <FontAwesomeIcon icon={each.icon} className="filter-icon" />
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    );
  };

  sortClick = attribute => {
    const { currentSort } = this.state;
    let nextSort = '';
    if (currentSort[attribute] === 'ascending') {
      nextSort = 'descending';
    } else {
      nextSort = 'ascending';
    }
    this.setState({
      currentSort: {
        ...this.state.currentSort,
        [attribute]: nextSort
      },
      attribute
    });
  };

  render() {
    let {
      objects,
      filter,
      defaultData,
      healthColorMap,
      loading,
      filterValue,
      currentSort,
      attribute,
      selectedFilter
    } = this.state;
    const searchbarPlaceholder = this.getPlaceholder();
    if (loading) {
      return null;
    }

    if (defaultData && selectedFilter === 'Location') {
      const filterIcon = FilterIcon[currentSort[attribute]];
      const newData = filterIcon.filterFunc(defaultData, attribute);
      return (
        <Fragment>
          {this.filterBar(searchbarPlaceholder)}
          <SideFilter
            filter={filter}
            selectedFilter={selectedFilter}
            handleSelected={this.handleSelected}
          />
          <div className={Style.grid_view_container}>
            {newData &&
              newData.map(object =>
                object.groupInfo.name.toLowerCase().includes(filterValue.toLowerCase()) ? (
                  <GridCard
                    groupBy={null}
                    filter={filter}
                    key={object.groupInfo.id}
                    object_type_id={object.groupInfo.id}
                    name={object.groupInfo.name}
                    imageUrl={null}
                    quantity={object.groupInfo.attributes.count}
                    timeLastUpdated={object.received_timestamp}
                    healthColor={healthColorMap[object.groupInfo.name]}
                    config={this.props.config}
                  />
                ) : null
              )}
          </div>
        </Fragment>
      );
    }
    objects = FilterIcon[currentSort[attribute]].filterFunc(objects, attribute);

    return (
      <Fragment>
        {this.filterBar(searchbarPlaceholder)}
        {!filter && selectedFilter === 'All Orders' ? (
          <SideFilter
            filter={filter}
            selectedFilter={selectedFilter}
            handleSelected={this.handleSelected}
          />
        ) : null}
        <div className={Style.grid_view_container}>
          {objects.map(object =>
            object.object_name.toLowerCase().includes(filterValue.toLowerCase()) ||
            (object.part_id && object.part_id.toLowerCase().includes(filterValue.toLowerCase())) ? (
              <GridCard
                part_id={object.part_id}
                key={object.tracked_object_id}
                filter={true}
                lastSeenLocation={object.zone.zone_name}
                object_type_id={object.object_type_id}
                tracked_object_id={object.tracked_object_id}
                name={object.object_name}
                imageUrl={object.image_url}
                trackerName={object.tracker_serial}
                isExpedited={object.is_expedited}
                object_type_id={object.tracked_object_id}
                timeLastUpdated={object.received_timestamp || object.published_timestamp}
                creationTime={object.creation_date}
                dueDate={object.due_date}
                config={this.props.config}
              />
            ) : null
          )}
        </div>
      </Fragment>
    );
  }
}

// GridView.propTypes = {
//   objects: PropTypes.shape(
//     PropTypes.shape({
//       object_type_name: PropTypes.string.isRequired,
//       image_url: PropTypes.string
//     }).isRequired
//   ).isRequired
// };
