import RestService from './RestService';
import ConfigurationService from './ConfigurationService';

const TIMESPAN = 'week';
const LOWSTOCK = 'Low Stock';
const OVERSTOCK = 'Overstock';
export default class TrackService {
  constructor(objectTypeData, snapData, consumptionHistoryData, lowStockData, overStockData) {
    const inventoryCount = {};
    this.consumptionHistoryData = consumptionHistoryData;
    snapData.forEach(each => {
      inventoryCount[each.object_type_id] = inventoryCount[each.object_type_id]
        ? inventoryCount[each.object_type_id] + 1
        : 1;
    });
    const config = ConfigurationService.getRestServiceConfiguration();
    this.trackData = {};
    objectTypeData.forEach(objectType => {
      this.trackData[objectType.object_type_id] = objectType;
      if (objectType.image) {
        this.trackData[
          objectType.object_type_id
        ].image_url = `${config.imageBucket.endpoint}/${objectType.image.image_url}`;
      } else {
        this.trackData[objectType.object_type_id].image_url = null;
      }
      this.trackData[objectType.object_type_id].quantity = 0;
      this.trackData[objectType.object_type_id].quantity =
        inventoryCount[objectType.object_type_id] || 0;
      this.trackData[objectType.object_type_id].low_stock_threshold = 0;
      this.trackData[objectType.object_type_id].over_stock_threshold = undefined;
    });

    if (lowStockData) {
      lowStockData.forEach(lowStock => {
        if (this.trackData[lowStock.tags.object_type_id]) {
          this.trackData[lowStock.tags.object_type_id].low_stock_threshold = parseInt(
            lowStock.tags.threshold_value,
            10
          );
        }
      });
    }

    if (overStockData) {
      overStockData.forEach(overStock => {
        this.trackData[overStock.tags.object_type_id].over_stock_threshold = parseInt(
          overStock.tags.threshold_value,
          10
        );
      });
    }
  }

  getWeeklyStatus() {
    /* format:
    weeklyStatus: {
      add: number,
      consume: nubmer,
      rate_per_day: number
    }
  */
    const weeklyStatus = { add: 0, consume: 0, rate_per_day: 0 };
    this.consumptionHistoryData.forEach(each => {
      weeklyStatus[each.action] += 1;
    });
    weeklyStatus.rate_per_day = Number((weeklyStatus.consume / 7).toFixed(1));
    return weeklyStatus;
  }

  getOverviewData() {
    const overview = {
      health: '',
      total_units: 0,
      total_items: 0,
      out_of_stock: 0,
      low_stock: 0,
      overstock: 0,
      good: 0
    };
    Object.keys(this.trackData).forEach(eachkey => {
      const { quantity, over_stock_threshold, low_stock_threshold } = {
        ...this.trackData[eachkey]
      };

      if (quantity === 0) {
        overview.out_of_stock += 1;
      } else if (quantity <= low_stock_threshold) {
        overview.low_stock += 1;
      } else if (quantity >= over_stock_threshold) {
        overview.overstock += 1;
      } else {
        overview.good += 1;
      }
      overview.total_items += 1;
      overview.total_units += quantity;
    });
    const percentage =
      (overview.low_stock + overview.out_of_stock + overview.overstock) / overview.total_items;
    if (percentage >= 0.7) {
      overview.health = 'Critical';
    } else if (percentage >= 0.5) {
      overview.health = 'Warning';
    } else {
      overview.health = 'Healthy';
    }
    return overview;
  }

  getTrackDataList() {
    const objectList = [];
    Object.keys(this.trackData).forEach(eachkey => {
      if (this.trackData[eachkey].quantity === 0) {
        this.trackData[eachkey].status = 'out of stock';
        this.trackData[eachkey].sortKey = 1;
      } else if (this.trackData[eachkey].quantity <= this.trackData[eachkey].low_stock_threshold) {
        this.trackData[eachkey].status = 'low stock';
        this.trackData[eachkey].sortKey = 2;
      } else if (this.trackData[eachkey].quantity >= this.trackData[eachkey].over_stock_threshold) {
        this.trackData[eachkey].status = 'overstock';
        this.trackData[eachkey].sortKey = 3;
      } else {
        this.trackData[eachkey].status = 'good';
        this.trackData[eachkey].sortKey = 4;
      }
      objectList.push(this.trackData[eachkey]);
    });
    return objectList;
  }

  static async build() {
    const objectTypeData = await RestService.get('/tracked-object-types');
    const snapData = await RestService.get('/tracked-objects/active');
    const plant = await RestService.get('/plants');
    const consumptionHistoryData =
      plant.length !== 0
        ? await RestService.get(
            `/analytics/consumption-history?plant_id=${plant[0].plant_id}&timespan=${TIMESPAN}`
          )
        : [];
    const ruleData = await RestService.get('/rules');

    let rule = ruleData.find(o => o.name === LOWSTOCK);
    const lowStockData = rule && (await RestService.get(`/rules/${rule.rule_id}/conditions`));
    rule = ruleData.find(o => o.name === OVERSTOCK);
    const overStockData = rule && (await RestService.get(`/rules/${rule.rule_id}/conditions`));

    return new TrackService(
      objectTypeData,
      snapData,
      consumptionHistoryData,
      lowStockData,
      overStockData
    );
  }
}
