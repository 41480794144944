import RestService from '../../../services/RestService';
import ResourceProvider from './ResourceProvider';
import _ from 'lodash';
import React from 'react';

const resourceProviderName = 'Gateway';
const parentName = 'Zone';

export default class GatewayResource extends ResourceProvider {
  static async build() {
    let getGateways = async () => {
      let gateways = await RestService.get('/gateways');

      let gatewayList = _.map(gateways, gateway => {
        let gatewayId = gateway.gateway_id;
        let gatewayZoneId = gateway.zone_id;
        let gatewaySerial = gateway.gateway_serial_number;

        return {
          thisName: gatewaySerial,
          thisId: gatewayId,
          zoneId: gatewayZoneId
        };
      });

      return gatewayList;
    };

    let gateways = await getGateways();

    return new GatewayResource(
      [...gateways.sort((a, b) => a.thisName.localeCompare(b.thisName))],
      resourceProviderName
    );
  }

  deleteItem(thisItem, handleCloseFunc, showDeleteErrorFunc) {
    RestService.delete('/gateways/' + thisItem.thisId).then(
      result => {
        this.resourceArray = this.resourceArray.filter(function(gateway) {
          return gateway !== thisItem;
        });

        handleCloseFunc();
      },
      err => {
        showDeleteErrorFunc();
      }
    );
  }

  editItemsSchema(index, itemToEdit, changeValuesFunc, resourceProviders, nameCheck) {
    let name = resourceProviders
      .find(provider => provider.getName() === parentName)
      .resourceArray.filter(obj => {
        return obj.thisId === itemToEdit.zoneId;
      })[0].thisName;
    return (
      <div>
        {super.editParentIdForm(
          name,
          resourceProviders.find(provider => provider.getName() === parentName)
        )}
        {super.editLevelNameForm(index, itemToEdit, changeValuesFunc, nameCheck)}
      </div>
    );
  }

  addItemsSchema(index, item, changeValuesFunc, resourceProviders, nameCheck, parentIdCheck) {
    return (
      <div>
        {super.parentIdForm(
          index,
          item.parentId,
          resourceProviders.find(provider => provider.getName() === parentName),
          changeValuesFunc,
          parentIdCheck
        )}
        {super.levelNameForm(index, item.name, changeValuesFunc, nameCheck)}
      </div>
    );
  }

  //edit gateway is not currently supported
  async editItem(
    formCheckFunc,
    updateErrorsFunc,
    stagedItems,
    resourceProviders,
    customerId,
    itemToEdit
  ) {
    return true;
  }

  async addItem(formCheckFunc, updateErrorsFunc, stagedItems) {
    let errorCollection = [];
    let successCollection = [];
    let successRegistry = [];

    let nameChecks = formCheckFunc('name');
    let parentCheck = formCheckFunc('parentId');

    if (!nameChecks || !parentCheck) {
      return false;
    }

    for (const index of stagedItems.keys()) {
      let payload = {
        zone_id: stagedItems[index].parentId,
        gateway_serial_number: stagedItems[index].name
      };

      await RestService.post('/gateways', payload).then(
        result => {
          this.add({
            thisName: result.gateway_serial_number,
            thisId: result.gateway_id,
            zoneId: result.zone_id
          });

          successCollection.push(result.gateway_serial_number);
          successRegistry.push(index);
        },
        err => {
          errorCollection.push({
            entry: stagedItems[index].name,
            errMessage: err.response.data.message
          });
        }
      );
    }

    updateErrorsFunc(successRegistry, successCollection, errorCollection);
    return true;
  }
}
