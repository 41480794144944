import { Tabs, Tab, Alert } from 'react-bootstrap';
import { Button, Card, CardBody, CardTitle, CardGroup } from 'mdbreact';
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { getSimpleName } from 'common/Utilities';
import AbstractHomePage from 'pages/home/AbstractHomePage';
import HistoricalBackLogGraph from 'pages/report/HistoricalBackLogGraph';
import RestService from '../../services/RestService';
import './DepartmentThresholdPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class DepartmentThresholdPage extends Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.getDepartmentThreshold = this.getDepartmentThreshold.bind(this);
    this.loadDepartmentData = this.loadDepartmentData.bind(this);
    this.revertInputChange = this.revertInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);

    this.state = {
      departments: [],
      activeTab: 'dept_threshold',
      errors: {},
      shouldNotify: {},
      defaultValues: {},
      inputs: {},
      plantId: '',
      departmentId: '',
      isValidThreshold: '',
      showErrorMessage: true,
      tabNumber: 2
    };
  }

  getDepartmentThreshold = async departmentId => {
    const department = await RestService.get(`/departments/${departmentId}`);

    const departmentThreshold = department.thresholds;
    const orderThresholdWarning = departmentThreshold
      ? departmentThreshold.orderThresholdWarning
      : 0;
    const orderThresholdCritical = departmentThreshold
      ? departmentThreshold.orderThresholdCritical
      : 0;

    return {
      departmentId,
      plantId: department.plant_id,
      threshold: {
        warning: orderThresholdWarning,
        critical: orderThresholdCritical
      }
    };
  };

  loadDepartmentData = departmentData => {
    this.setState({
      defaultValues: {
        warning: departmentData.threshold.warning,
        critical: departmentData.threshold.critical
      },

      inputs: {
        warning: departmentData.threshold.warning,
        critical: departmentData.threshold.critical
      },

      activeTab: departmentData.departmentId,

      plantId: departmentData.plantId
    });
  };

  async UNSAFE_componentWillMount() {
    // region:helper methods
    const getDepartmentsInfo = async () => {
      const departments = await RestService.get('/departments');

      const departmentList = _.map(departments, department => {
        const departmentName = department.department_name;
        const simpleName = getSimpleName(departmentName);
        const departmentId = department.department_id;

        return {
          departmentName,
          shortName: simpleName,
          departmentId
        };
      });

      return departmentList;
    };

    const getInitialDepartment = async departments => {
      if (departments && departments.length > 0) {
        const departmentId = departments[0].departmentId;

        const thresholdInfo = this.getDepartmentThreshold(departmentId);
        return thresholdInfo;
      }

      return null;
    };

    // endregion

    const departments = await getDepartmentsInfo();
    const selectedDepartment = await getInitialDepartment(departments);

    if (selectedDepartment) {
      this.loadDepartmentData(selectedDepartment);
    }

    this.setState({ departments: [...departments] });
  }

  handleSelect(key) {
    const loadDepartmentDataFn = this.loadDepartmentData;
    this.getDepartmentThreshold(key).then(departmentData => {
      loadDepartmentDataFn(departmentData);
    });
  }

  isValueInput = input => {
    // integer value only
    if (!/^[1-9]?[0-9]*$/.test(input)) {
      return false;
    }

    if (input < 0) {
      return false;
    }

    return true;
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;

    const idPrefix = 'inputs.';
    const id = target.id.substring(idPrefix.length);

    if (!this.isValueInput(value)) {
      this.setState({
        errors: { ...this.state.errors, [id]: true }
      });

      return;
    }

    this.setState({
      inputs: {
        ...this.state.inputs,
        [id]: value
      }
    });
  };

  revertInputChange = () => {
    this.setState({
      inputs: {
        ...this.state.inputs,
        warning: this.state.defaultValues.warning,
        critical: this.state.defaultValues.critical
      },
      showErrorMessage: false
    });
  };

  handleSubmit = () => {
    this.setState({ showErrorMessage: true });

    if (+this.state.inputs.critical < +this.state.inputs.warning) {
      this.setState({ isValidThreshold: 'incorrect' });
      return;
    }

    this.setState({ isValidThreshold: 'correct' });

    const payload = [
      {
        op: 'replace',
        path: '/thresholds/orderThresholdCritical',
        value: this.state.inputs.critical
      },
      {
        op: 'replace',
        path: '/thresholds/orderThresholdWarning',
        value: this.state.inputs.warning
      }
    ];

    RestService.patch(`/departments/${this.state.activeTab}`, payload).then(
      result => {
        this.setState({
          defaultValues: {
            warning: this.state.inputs.warning,
            critical: this.state.inputs.critical
          }
        });
      },
      err => {
        const status = err.request.status;
        this.setState({
          error: {
            status,
            message: err.response.data
          },
          show_page: 'error'
        });
        this.setState({ doneFetching: true });
      }
    );
  };

  validateThreshold = () => {
    if (this.state.isValidThreshold === 'incorrect') {
      return (
        <Alert className="threshold-alerts" bsStyle="danger" onDismiss={this.handleDismiss}>
          <h4>Error: Your values were not submitted</h4>
          <p>
            The threshold for 'warning' may not be larger than the threshold for 'critical'. Please
            change and submit again.
          </p>
        </Alert>
      );
    }
    if (this.state.isValidThreshold === 'correct') {
      return (
        <Alert className="threshold-alerts" bsStyle="success" onDismiss={this.handleDismiss}>
          <h4>Success!</h4>
          <p>Your threshold values have been recorded.</p>
        </Alert>
      );
    }
  };

  handleDismiss = () => {
    this.setState({ showErrorMessage: false });
  };

  render() {
    const isSuperAdmin = sessionStorage.getItem('isSuperAdmin') === 'true';
    return (
      <Fragment>
        <AbstractHomePage />
        {this.state.showErrorMessage && this.validateThreshold()}
        <Card className="main-card">
          <CardBody id="main-card-body">
            <Tabs activeKey={this.state.activeTab} onSelect={this.handleSelect} id="dep-threshold">
              {this.state.departments.map(dep => {
                return (
                  <Tab key={dep.departmentId} eventKey={dep.departmentId} title={dep.shortName}>
                    <CardGroup deck className="card-group-body">
                      <Card className="card-inner-thresholds">
                        <CardTitle className="threshold-detail-title">
                          {dep.departmentName} Threshold
                        </CardTitle>
                        <CardBody>
                          <p> Enter threshold values </p>
                          <form>
                            <div>
                              <div className="threshold-record" id="threshold-record-input-warning">
                                <div className="threshold-record-icon-label">
                                  <i
                                    className="fa fa-circle threshold-icon warning-threshold"
                                    aria-hidden="true"
                                  />
                                  <span className="threshold-record-label">Warning Threshold</span>
                                </div>
                                <div className="threshold-record-input">
                                  <input
                                    value={this.state.inputs.warning}
                                    id="inputs.warning"
                                    disabled={!isSuperAdmin}
                                    onChange={this.handleInputChange}
                                    type="text"
                                  />
                                </div>
                              </div>

                              <div
                                className="threshold-record"
                                id="threshold-record-input-critical"
                              >
                                <div className="threshold-record-icon-label">
                                  <i
                                    className="fa fa-circle threshold-icon critical-threshold"
                                    aria-hidden="true"
                                  />
                                  <span className="threshold-record-label">Critical Threshold</span>
                                </div>

                                <div className="threshold-record-input">
                                  <input
                                    value={this.state.inputs.critical}
                                    id="inputs.critical"
                                    disabled={!isSuperAdmin}
                                    onChange={this.handleInputChange}
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="threshold-form-buttons">
                              <Button
                                color="danger"
                                rounded
                                disabled={!isSuperAdmin}
                                onClick={this.revertInputChange}
                              >
                                Revert
                              </Button>
                              <Button
                                color="success"
                                rounded
                                disabled={!isSuperAdmin}
                                onClick={this.handleSubmit}
                              >
                                Submit
                              </Button>
                            </div>
                          </form>
                        </CardBody>
                      </Card>
                      <Card className="historical-backlog-graph">
                        <h4>
                          {' '}
                          Historical Backlog for
                          {dep.departmentName}{' '}
                        </h4>
                        <CardBody className="backlog-body">
                          {this.state.activeTab === dep.departmentId &&
                            this.state.defaultValues !== undefined && (
                              <HistoricalBackLogGraph
                                key={`${this.state.defaultValues.critical}-${this.state.defaultValues.warning}`}
                                domainItemName={dep.departmentName}
                                deptThresholds={this.state.defaultValues}
                                hideLegend
                              />
                            )}
                        </CardBody>
                      </Card>
                    </CardGroup>
                  </Tab>
                );
              })}
            </Tabs>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}
