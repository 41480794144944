import React, { Component, Fragment } from 'react';
import TrackedObjectService from 'services/TrackedObjectService';
import ConfigurationService from 'services/ConfigurationService';
import RestService from 'services/RestService';
import { getCustomerLogo } from 'common/Utilities';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'mdbreact';
import Switch from 'react-switch';
import Moment from 'react-moment';
import ConfirmationModal from 'components/ConfirmationModal';
import DetailsCard from 'components/DetailsCard';
import ZoneResource from '../manage-infrastructure/resources/ZoneResource';
import TrackObjectNotFoundPage from '../error/TrackObjectNotFoundPage';
import detailsUtils from './DetailsUtils';

export default class OrderDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trackedObjectId: this.props.search,
      info: undefined,
      loading: true,
      validOrder: false,
      doneFetching: false,
      imgInfo: undefined,
      overallInformation: [],
      metaInformation: null,
      expandedRows: [],
      enableEdit: false,
      displayModal: false,
      showDeleteModal: false,
      deleteClicked: false,
      deleteSuccess: false
    };
  }

  componentDidMount() {
    this.collectData();
  }

  async UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.search !== newProps.search) {
      await this.setState({
        trackedObjectId: newProps.search
      });
      this.collectData();
    }
  }

  getExpediteButton() {
    const { expedited } = this.state;
    return (
      <div className="expedite-group">
        <Switch
          onChange={this.handleExpediteChange}
          checked={expedited}
          id="normal-switch"
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#4d8605"
        />
        <span id="expedite-text">{expedited ? 'Being expedited' : 'Not expedited'}</span>
      </div>
    );
  }

  collectData = async () => {
    try {
      // get Route for object
      let info;
      let expedited;
      let image_info;

      info = await TrackedObjectService.build(this.state.trackedObjectId).then(routeData => {
        return routeData;
      });

      const config = await ConfigurationService.getFullConfigurations();
      image_info = getCustomerLogo();
      if (info.image) {
        const customerConfig = ConfigurationService.getFullConfigurations();
        image_info = `${customerConfig.s3.URL}/${info.image.image_url}`;
      }
      // get Zone image
      info.image_info =
        info && info.zone ? await ZoneResource.GetZoneImage(info.zone.zone_id) : getCustomerLogo();
      expedited = info.is_expedited;

      this.setState({
        info,
        image_info,
        validOrder: true,
        expedited,
        solutionType: config.solutionType,
        locationInfo: config.locationInfo
      });

      // set Order Card state
      this.setOrderState();

      this.setState({
        doneFetching: true
      });
    } catch (err) {
      console.log(err);
      this.setState({
        error: {
          status: err.response.data.errorType,
          message: err.response.data.message
        },
        doneFetching: true,
        validOrder: false
      });
    }
  };

  setOrderState = () => {
    const { info, image_info, enableEdit } = this.state;
    let recentTime = this.state.info.creation_date;
    if (info.order_route.length > 0) {
      const lastDeptZones = info.order_route[info.order_route.length - 1].zones;
      const lastZone = lastDeptZones[lastDeptZones.length - 1];
      recentTime = lastZone.enter_timestamp;
    }
    const overallInformation = [
      {
        name: 'Tag #:',
        action: detailsUtils.getTrackerSerial(info)
      },
      {
        name: 'Time Created:',
        action: this.displayTime(info.creation_date)
      },
      {
        name: 'Due Date:',
        editable: true,
        action: this.getDueDate()
      },
      {
        name: 'Location:',
        action: detailsUtils.getLocation(info, recentTime)
      },
      {
        name: 'Expedite: ',
        action: this.getExpediteButton()
      },
      {
        name: 'Button',
        action: detailsUtils.getEditActionButtons(enableEdit, this.cancelEdit, this.saveEdit)
      }
    ];
    const config = ConfigurationService.getFullConfigurations().preference;
    const metaInformation = {
      display_name: config ? config.PRIMARY.concat(':') : 'Order Number:',
      display_value: config ? info[config.PRIMARY_ATTR] : info.object_name,
      img: image_info,
      display_part_id: config ? config.SECONDARY.concat(':') : 'Part Number:',
      part_id: config ? info[config.SECOND_ATTR] : info.part_id || '-'
    };
    this.setState({ overallInformation, metaInformation });
  };

  getDueDate() {
    const { info } = this.state;
    if (info.due_date) {
      return (
        <div>
          <Moment format="MMM DD, YYYY">{info.due_date}</Moment>
        </div>
      );
    }
    return '-';
  }

  displayTime = timeStamp => {
    return (
      <Fragment>
        <div>
          <Moment format="hh:mm A">{timeStamp}</Moment>
        </div>
        <div>
          <Moment format="MMM DD, YYYY">{timeStamp}</Moment>
        </div>
      </Fragment>
    );
  };

  getDepartmentDurationForStage = route => {
    const length = route.zones.length;
    const enterTime = route.enter_timestamp;
    const exitTime = route.zones[length - 1].exit_timestamp;

    return exitTime
      ? detailsUtils.msToTime(exitTime - enterTime)
      : detailsUtils.msToTime(Date.now() - enterTime);
  };

  handleRowClick(that, rowId) {
    const newExpandedRows = detailsUtils.getExpandedRows(that.state.expandedRows, rowId);
    this.setState({ expandedRows: newExpandedRows });
  }

  saveEdit = async () => {
    const { editPayload, trackedObjectId } = this.state;
    TrackedObjectService.updateOrderDetails(trackedObjectId, editPayload);
    this.setState({ editPayload: {}, enableEdit: false });
    this.setOrderState();
  };

  handleExpediteChange = async event => {
    this.setState({
      expedited: await TrackedObjectService.expediteOrder(event, this.state.info.object_id)
    });
    this.setOrderState();
  };

  handleManualDisassociate = async () => {
    const { locationInfo, info } = this.state;
    const payload = {
      tracker_id: info.tracker_id,
      gateway_id: locationInfo.CONSUME_GATEWAY,
      zone_id: locationInfo.CONSUME_ZONE,
      signal_strength: '1234',
      tracker_timestamp: Date.now()
    };

    await RestService.post('/tracker-timeseries/publish', payload).then(() => {
      window.location.href = '/tracked-objects';
    });
  };

  closeModal = () => {
    this.setState({ displayModal: false });
  };

  closeDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  getMoreButtonOptions = () => {
    return (
      <DropdownMenu>
        <DropdownItem className="dropdown-item">
          <div onClick={this.handleEditClick}>Edit Order Detail</div>
        </DropdownItem>
        <DropdownItem className="dropdown-item">
          <form>
            <label className="dropdown-label">
              Change Order Photo
              <input hidden type="file" onChange={this.editImage} />
            </label>
          </form>
        </DropdownItem>
        <DropdownItem
          className="dropdown-item"
          onClick={() => {
            this.setState({ displayModal: true });
          }}
        >
          <div>Mark As Completed</div>
        </DropdownItem>
        <DropdownItem className="dropdown-item">
          <div
            className="delete-label"
            onClick={() => {
              this.setState({ showDeleteModal: true });
            }}
          >
            Delete Order
          </div>
        </DropdownItem>
      </DropdownMenu>
    );
  };

  editImage = async event => {
    detailsUtils.editImage(event, this);
  };

  handleEditChange = (id, value) => {
    this.setState({
      info: {
        ...this.state.info,
        [id]: value
      },
      editPayload: {
        ...this.state.editPayload,
        [id]: value
      }
    });
  };

  handleEditClick = async () => {
    await this.setState({
      enableEdit: true
    });
    this.setOrderState();
  };

  deleteObject = async () => {
    const id = this.state.info.object_id;
    const path = `/tracked-objects/${id}`;
    this.setState({showDeleteModal: false});
    await RestService.delete(path).then(
      result => {
        this.setState({ deleteClicked:true, deleteSuccess: true});
      },
      err => {
        this.setState({ deleteClicked:true, deleteSuccess: false});
      }
    );
  };

  handleDismiss = () => {
    this.setState({ deleteSuccess: false, deleteClicked: false });
  };

  cancelEdit = async () => {
    await this.setState({
      enableEdit: false
    });
    this.setOrderState();
  };

  renderMoreButton = () => {
    return (
      <div>
        <Dropdown>
          <DropdownToggle nav style={{ color: '#343434', float: 'right' }}>
            <MoreIcon />
          </DropdownToggle>
          {this.getMoreButtonOptions()}
        </Dropdown>
      </div>
    );
  };

  render() {
    const {
      doneFetching,
      validOrder,
      error,
      overallInformation,
      metaInformation,
      locationInfo,
      info,
      enableEdit,
      displayModal,
      showDeleteModal,
      deleteClicked,
      deleteSuccess
    } = this.state;

    if (doneFetching) {
      if (!validOrder) {
        return <TrackObjectNotFoundPage error={error} />;
      }
    }

    return (
      <Fragment>
        {
          <DetailsCard
            canEdit={enableEdit}
            overallInformation={overallInformation}
            metaInformation={metaInformation}
            handleEditChange={this.handleEditChange}
            renderMoreButton={locationInfo ? this.renderMoreButton : null}
            info={info}
            deleteClicked={deleteClicked}
            deleteSuccess={deleteSuccess}
            handleDismiss={this.handleDismiss}
          />
        }
        <div className="header-button-group">
          <h3>Order Route</h3>
        </div>
        <div className="order-backlog-body">
          {info ? detailsUtils.getOrderRouteView(this) : null}
        </div>
        {displayModal && (
          <ConfirmationModal
            title="Confirmation"
            bodyText="Mark order as Completed?"
            confirmFunction={this.handleManualDisassociate}
            cancelFunction={this.closeModal}
          />
        )}
        {showDeleteModal && (
          <ConfirmationModal
            title="Confirm Deletion"
            bodyText="Permanently delete this item?"
            confirmFunction={this.deleteObject}
            cancelFunction={this.closeDeleteModal}
          />
        )}
      </Fragment>
    );
  }
}
