import React, { PureComponent, Fragment } from 'react';
import {
  faSortAlphaDown,
  faSortAlphaUp,
  faSortAmountDownAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import GridCard from './GridCard';
import './css/GridView.css';

const FilterIcon = {
  ascending: {
    icon: faSortAlphaDown,
    filterFunc: objects => {
      return objects.sort((a, b) => {
        if (a.object_type_name < b.object_type_name) {
          return -1;
        }
        if (a.object_type_name > b.object_type_name) {
          return 1;
        }
        return 0;
      });
    }
  },
  descending: {
    icon: faSortAlphaUp,
    filterFunc: objects => {
      return objects.sort((a, b) => {
        if (a.object_type_name > b.object_type_name) {
          return -1;
        }
        if (a.object_type_name < b.object_type_name) {
          return 1;
        }
        return 0;
      });
    }
  },
  status: {
    icon: faSortAmountDownAlt,
    filterFunc: objects => {
      return objects.sort((a, b) => {
        if (a.sortKey === b.sortKey) {
          return a.quantity - b.quantity;
        }
        return a.sortKey - b.sortKey;
      });
    }
  }
};

/*
 * GridView takes props from InventoryComponent, passes data to GridCard,
 * and dynamically renders a GridCard for each object
 * Check GridCard.js for details on how each card is rendered
 */
export default class GridView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: '',
      placeholder: 'Filter Items',
      currentSort: 'status'
    };
    this.sortClick = this.sortClick.bind(this);
  }

  sortClick = () => {
    const { currentSort } = this.state;
    let nextSort = '';
    if (currentSort === 'ascending') {
      nextSort = 'descending';
    } else if (currentSort === 'descending') {
      nextSort = 'status';
    } else {
      nextSort = 'ascending';
    }
    this.setState({ currentSort: nextSort });
  };

  /*
   * Filter bar allows shuffling of GridCard order by ascending stock,
   * descending stock, and object status
   */
  filterBar = () => {
    const { filterValue, placeholder, currentSort } = this.state;

    return (
      <div className="filter-group">
        <FormControl
          type="text"
          className="grid-search-bar"
          value={filterValue}
          placeholder={placeholder}
          onChange={e => {
            this.setState({ filterValue: e.target.value });
          }}
        />
        <FontAwesomeIcon
          icon={FilterIcon[currentSort].icon}
          className="filter-icon"
          size="lg"
          onClick={this.sortClick}
        />
      </div>
    );
  };

  render() {
    const { filterValue, currentSort } = this.state;
    let { objects } = this.props;
    objects = FilterIcon[currentSort].filterFunc(objects);
    return (
      <Fragment>
        {this.filterBar()}
        <div className="grid-view-container">
          {objects.map(object =>
            object.object_type_name.toLowerCase().includes(filterValue.toLowerCase()) ? (
              <GridCard
                key={object.object_type_id}
                objectTypeId={object.object_type_id}
                name={object.object_type_name}
                imageUrl={object.image_url}
                lowStockThreshold={object.low_stock_threshold}
                overstockThreshold={object.over_stock_threshold}
                quantity={object.quantity}
                healthStatus={object.status}
              />
            ) : null
          )}
        </div>
      </Fragment>
    );
  }
}

GridView.propTypes = {
  objects: PropTypes.arrayOf(
    PropTypes.shape({
      object_type_name: PropTypes.string.isRequired,
      image_url: PropTypes.string,
      low_stock_threshold: PropTypes.number,
      over_stock_threshold: PropTypes.number,
      quantity: PropTypes.number
    }).isRequired
  ).isRequired
};
