import React, { Fragment } from 'react';
import Loading from '../loading/LoadingWord';
import './TabbedSectionComponent.css';

export default class TabbedSectionComponent extends React.Component {
  constructor(props) {
    super(props);

    let generateTabNameFn = this.generateTabName;
    if (props.generateTabNameFn) {
      generateTabNameFn = props.generateTabNameFn;
    }

    this.state = {
      tabContents: this.props.tabContents,
      generateTabNameFn
    };

    this.setActiveComponent = this.setActiveComponent.bind(this);
  }

  setActiveComponent(tabName, component) {
    this.setState({
      activeComponent: component,
      activeTabName: tabName
    });
  }

  hasActiveTab() {
    return this.state.activeTabName;
  }

  setActiveTab(tabContents) {
    if (this.hasActiveTab()) {
      return;
    }

    if (!tabContents) {
      tabContents = [];
    }

    if (tabContents.length > 0) {
      let activeTab = tabContents[0];
      if (this.props.activetab) activeTab = tabContents[this.props.activetab];
      const tabName = activeTab.name;
      const component = activeTab.component;

      this.setActiveComponent(tabName, component);
    }
  }

  componentDidMount() {
    const tabContents = this.state.tabContents;

    this.setActiveTab(tabContents);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const tabContents = props.tabContents;
    this.setState({ tabContents });
    this.setActiveTab(tabContents);
  }

  generateTabName(tabContent, className, setActiveComponentFn) {
    const tabName = tabContent.name;
    const component = tabContent.component;
    return (
      <div
        key={tabName}
        className={className}
        onClick={() => setActiveComponentFn(tabName, component)}
      >
        {tabName}
      </div>
    );
  }

  render() {
    const activeTab = this.state.activeTabName;

    let rootClassName = 'tabbedSectionComponent-container';
    if (this.props.className) {
      rootClassName += ` ${this.props.className}`;
    }

    return (
      <div className={rootClassName}>
        {!this.state.tabContents ? (
          <Loading />
        ) : (
          <Fragment>
            <div className="tabbedSectionComponent-tabs">
              {this.state.tabContents.map(tabContent => {
                const tabName = tabContent.name;

                let className = 'tab';
                if (activeTab === tabName) {
                  className = `${className} active`;
                } else {
                  className = `${className} clickable`;
                }

                const tabTag = this.state.generateTabNameFn(
                  tabContent,
                  className,
                  this.setActiveComponent
                );
                return tabTag;
              })}
            </div>
            <div className="tabbedSectionComponent-content">
              {this.state.activeComponent ? <this.state.activeComponent /> : undefined}
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}
