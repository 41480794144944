import React from 'react';
import RestService from './RestService';
import ResourceProvider from 'pages/manage-infrastructure/resources/ResourceProvider';
import _ from 'lodash';

const resourceProviderName = 'Part Type';

const schema = [
  {
    param_name: 'objectTypeId',
    display_name: 'Item ID',
    default_value: '',
    validation: function(input) {
      return input && input !== '';
    }
  },
  {
    param_name: 'objectTypeName',
    display_name: 'Item Name',
    sort: 'asc',
    default_value: '',
    validation: function(input) {
      return input && input !== '';
    }
  }
];
const imageDetails = '';

export default class ObjectTypeService extends ResourceProvider {
  static async build() {
    let getObjectType = async () => {
      let types = await RestService.get('/tracked-object-types');

      let typeList = _.map(types, type => {
        return {
          objectTypeName: type.object_type_name,
          objectTypeId: type.object_type_id
        };
      });

      return typeList;
    };

    let types = await getObjectType();
    return new ObjectTypeService([...types], resourceProviderName);
  }

  getInputSchema() {
    let result = {};
    schema.forEach(schemaItem => {
      result[schemaItem.param_name] = schemaItem.defualt_value;
    });
    return result;
  }

  getSchema() {
    return _.cloneDeep(schema);
  }

  validateInput(inputs) {
    let report = {
      hasError: false,
      results: []
    };
    schema.forEach(schemaItem => {
      let isValid = schemaItem.validation(inputs[schemaItem.param_name]);
      if (!isValid) {
        report.hasError = true;
      }
      report.results[schemaItem.display_name] = isValid;
    });

    return report;
  }

  itemsSchema(
    index,
    changeValuesFunc,
    inputValidation,
    imageValuesFunc,
    objectIDChangeable,
    placeholder
  ) {
    return (
      <div>
        {schema.map(item => {
          let valid = true;
          if (inputValidation) {
            valid = inputValidation[item.display_name];
          }
          if (item.param_name !== 'objectTypeId' || objectIDChangeable) {
            return super.textForm(
              index,
              item,
              changeValuesFunc,
              valid,
              placeholder ? placeholder[item.param_name] : null
            );
          } else {
            return null;
          }
        })}
        {super.imageForm(index, imageDetails, imageValuesFunc)}
      </div>
    );
  }

  async editItem(updateErrorsFunc, stagedItems) {
    let errorCollection = [];
    let successCollection = [];
    let successRegistry = [];
    let payload = [];
    if (stagedItems[0].objectTypeName) {
      payload.push({
        op: 'replace',
        path: '/object_type_name',
        value: stagedItems[0].objectTypeName
      });
    }
    let imageObject = '';
    if (stagedItems[0].imageDetails) {
      await RestService.uploadImage(
        '/images',
        stagedItems[0].imageDetails,
        stagedItems[0].imageDetails.type
      ).then(
        result => {
          imageObject = result.data;
          successCollection.push(result.data);
        },
        err => {
          errorCollection.push({
            entry: stagedItems[0].imageDetails.name,
            errMessage: err.response.data.message
          });
        }
      );
    }
    if (errorCollection.length > 0) {
      updateErrorsFunc(successRegistry, successCollection, errorCollection);
      return true;
    }
    if (imageObject !== '') {
      payload.image = imageObject;
      payload.push({
        op: 'replace',
        path: '/image',
        value: imageObject
      });
    }
    await RestService.patch('/tracked-object-types/' + stagedItems[0].objectTypeId, payload).then(
      result => {
        //console.log(result);
        this.resourceArray = this.resourceArray.filter(function(objectType) {
          return objectType.objectTypeId !== result.object_type_id;
        });
        this.add({
          objectTypeId: result.object_type_id,
          objectTypeName: result.object_type_name
        });

        successCollection.push(result.object_type_name);
        successRegistry.push(0);
      },
      err => {
        console.log(err);
        errorCollection.push({ entry: stagedItems[0].name, errMessage: err.response.data.message });
      }
    );
    updateErrorsFunc(successRegistry, successCollection, errorCollection);
    return true;
  }

  async addItem(updateErrorsFunc, stagedItems) {
    let errorCollection = [];
    let successCollection = [];
    let successRegistry = [];

    for (const index of stagedItems.keys()) {
      let payload = {
        object_type_id: stagedItems[index].objectTypeId,
        object_type_name: stagedItems[index].objectTypeName
      };

      let imageObject = '';

      if (stagedItems[index].imageDetails) {
        await RestService.uploadImage(
          '/images',
          stagedItems[index].imageDetails,
          stagedItems[index].imageDetails.type
        ).then(
          result => {
            imageObject = result.data;
            successCollection.push(result.data);
          },
          err => {
            errorCollection.push({
              entry: stagedItems[index].imageDetails.name,
              errMessage: err.response.data.message
            });
          }
        );
      }

      if (errorCollection.length > 0) {
        updateErrorsFunc(successRegistry, successCollection, errorCollection);
        return true;
      }

      if (imageObject !== '') {
        payload.image = imageObject;
      }

      await RestService.post('/tracked-object-types', payload).then(
        result => {
          this.add({
            objectTypeId: result.object_type_id,
            objectTypeName: result.object_type_name
          });

          successCollection.push(result.object_type_name);
          successRegistry.push(index);
        },
        err => {
          errorCollection.push({
            entry: stagedItems[index].name,
            errMessage: err.response.data.message
          });
        }
      );
    }

    updateErrorsFunc(successRegistry, successCollection, errorCollection);
    return true;
  }

  async deleteItem(target) {
    await RestService.delete('/tracked-object-types/' + target.objectTypeId).then(
      result => {
        this.resourceArray = this.resourceArray.filter(function(objectType) {
          return objectType.objectTypeId !== target.objectTypeId;
        });
      },
      err => {
        console.log(err);
      }
    );
  }
}
