import ConfigurationService from 'services/ConfigurationService';
import moment from 'moment';

export function getSimpleName(fullName) {
  // if has white space
  if (/\s/g.test(fullName)) {
    const matches = fullName.match(/\b(\w)/g); // ['J','S','O','N']
    const acronym = matches.join('');
    return acronym;
  }

  if (fullName.length > 6) {
    return fullName.substring(0, 3);
  }
  return fullName;
}

export function getFormattedDate(date, format) {
  const finalFormat = format || 'MM/DD/YYYY HH:mm:ss';
  return moment(date).format(finalFormat);
}

// The format of dateCell is mm/dd/yyyy hh:mm:ss
export function dateCellToDate(dateCell) {
  return new Date(dateCell);
}

export function dateComparatorSort(dateCell1, dateCell2) {
  // In the example application, dates are stored as mm/dd/yyyy hh:mm:ss
  // We create a Date object for comparison against the filter date
  if (dateCell2 == null) return -1;
  if (dateCell1 == null) return 1;

  const date1 = dateCellToDate(dateCell1);
  const date2 = dateCellToDate(dateCell2);

  // Now that both parameters are Date objects, we can compare
  if (date2 < date1) {
    return -1;
  }
  if (date2 > date1) {
    return 1;
  }
  return 0;
}

export function dateComparatorFilter(filterLocalDateAtMidnight, cellValue) {
  // In the example application, dates are stored as mm/dd/yyyy  hh:mm:ss
  // We create a Date object for comparison against the filter date
  if (cellValue == null) return -1;
  const cellDate = dateCellToDate(cellValue);

  // Now that both parameters are Date objects, we can compare
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
}

export function getHomePageImage() {
  const images = importAllImages(require.context('../img', false, /(_homepage)\.(png)$/));
  const imageName = `${ConfigurationService.getCustomerName()}_homepage.png`;
  return images[`${imageName}`];
}

export function getCustomerLogoSmall() {
  const images = importAllImages(require.context('../img', false, /(_logo_small)\.(png)$/));
  const imageName = `${ConfigurationService.getCustomerName()}_logo_small.png`;
  return images[`${imageName}`];
}

export function getCustomerLogo() {
  const images = importAllImages(require.context('../img', false, /(_logo)\.(png)$/));
  const imageName = `${ConfigurationService.getCustomerName()}_logo.png`;
  return images[`${imageName}`];
}

export function getCustomerDemo() {
  const images = importAllImages(require.context('../img', false, /(_demo)\.(png)$/));
  const imageName = `${ConfigurationService.getCustomerName()}_demo.png`;
  return images[`${imageName}`];
}

function importAllImages(r) {
  const images = {};
  r.keys().forEach(item => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}
