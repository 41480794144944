import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Style from './css/GridCard.module.css';
import { getFormattedDate } from '../../../common/Utilities';
import ReplacementImage from '../../../logo/xemelgo-logo.jpg';

export default class GridCard extends PureComponent {
  constructor(props) {
    super(props);
    const {
      name,
      object_type_id,
      part_id,
      timeLastUpdated,
      quantity,
      filter,
      lastSeenLocation,
      trackerName,
      isExpedited,
      creationTime,
      dueDate
    } = this.props;
    let healthColor = this.props.healthColor ? this.props.healthColor : '#C6CBD4';
    if (filter) {
      const { dueDate, isExpedited } = this.props;
      healthColor = this.getHealthColor(dueDate, isExpedited);
    }
    const { imageUrl } = this.props;
    this.state = {
      src: imageUrl === null ? ReplacementImage : imageUrl,
      errored: false,
      objectTypeId: object_type_id,
      part_id,
      quantity,
      name,
      filter,
      lastSeenLocation,
      timeLastUpdated,
      trackerName,
      isExpedited,
      healthColor,
      creationTime,
      dueDate
    };
  }

  getHealthColor(dueDate, isExpedited) {
    const healthColorMap = {
      red: '#FF3D46',
      yellow: '#F6B045',
      green: '#62D32E',
      gray: '#C6CBD4',
      orange: '#E35E01'
    };
    if (dueDate == null) {
      return healthColorMap.gray;
    }
    const date = new Date();
    const timeToExpiration = dueDate - date.getTime();
    if (isExpedited) {
      return healthColorMap.orange;
    }
    if (timeToExpiration <= 0) {
      return healthColorMap.red;
    }
    if (timeToExpiration <= 604800000) {
      return healthColorMap.yellow;
    }
    return healthColorMap.green;
  }

  getOverallGridCard = (borderColor, quantity, name, objectTypeId, time, date) => {
    return (
      <Fragment>
        <a
          style={borderColor}
          className={`${Style.card_container} ${Style.order_container}`}
          title={
            quantity.total.value > 0 ? `Click to view orders in ${name}` : `No orders in ${name}.`
          }
          href={quantity.total.value > 0 ? `tracked-objects/?location=${objectTypeId}` : null}
        >
          <div className={Style.top}>
            <div className={Style.details}>
              <p className={Style.name}>{name}</p>

              <div className={`${Style.justify_center} ${Style.flex_direction_row}`}>
                <p className={`${Style.label} ${Style.min_width_label}`}>Velocity Rate: </p>
                <p className={Style.velocity_rate}> N/A</p>
              </div>
            </div>
          </div>
          <hr className={Style.horizontal_line2} />
          <div className={`${Style.bottom} ${Style.flex_direction_row}`}>
            <div className={Style.last_seen}>
              <p className={Style.last_seen_title}>{quantity.total.name}</p>
              <p className={Style.last_seen_data}>{quantity.total.value}</p>
            </div>
            <div className={Style.last_updated}>
              <p className={Style.last_updated_title}>Last Updated</p>
              <p className={Style.last_updated_time}>{time && date ? `${time} - ${date}` : '-'}</p>
            </div>
          </div>
        </a>
      </Fragment>
    );
  };

  getDetailGridCard = (
    borderColor,
    objectTypeId,
    name,
    src,
    trackerName,
    isExpedited,
    part_id,
    lastSeenLocation,
    time,
    date,
    creDate,
    dueDate
  ) => {
    const top = this.props.config.preference
      ? this.props.config.preference.SECONDARY
      : 'Part Number';
    return (
      <Fragment>
        <a
          style={borderColor}
          className={`${Style.card_container} ${Style.order_container}`}
          href={`/tracked-object/details?type=order&&item=${objectTypeId}`}
        >
          <div className={Style.top}>
            <img
              className={Style.card_image}
              src={src}
              alt="Failed to load"
              onError={this.onError}
            />
            <div className={Style.details}>
              <p className={Style.name}>{name}</p>
              {part_id ? (
                <p className={`${Style.label} ${Style.min_width_label}`}>{`${top}: ${part_id}`}</p>
              ) : (
                <div className={`${Style.empty_label}`} />
              )}
              <p className={`${Style.label} ${Style.min_width_label}`}>
                {`Tag: ..${trackerName.substring(trackerName.length - 5, trackerName.length)}`}
              </p>
            </div>
          </div>

          {isExpedited ? <p className={Style.order_status}>Expedited</p> : null}
          <hr className={isExpedited ? Style.horizontal_line : Style.horizontal_line2} />

          <div className={`${Style.bottom} ${Style.flex_direction_row}`}>
            <div className={Style.last_seen}>
              <p className={Style.last_seen_title}>Due Date</p>
              <p className={Style.last_seen_data}>{dueDate || '-'}</p>
            </div>
            <div className={Style.last_updated}>
              <p className={Style.last_updated_title}>Last Updated</p>
              <p className={Style.last_updated_time}>{time && date ? `${time} - ${date}` : '-'}</p>
            </div>
            <div className={Style.last_updated}>
              <p className={Style.last_updated_title}>Date Created</p>
              <p className={Style.creation_time}>{creDate ? `${creDate}` : '-'}</p>
            </div>
          </div>
        </a>
      </Fragment>
    );
  };

  onError = () => {
    const { errored } = this.state;
    if (!errored) {
      this.setState({
        src: ReplacementImage,
        errored: true
      });
    }
  };

  render() {
    const {
      name,
      healthColor,
      timeLastUpdated,
      quantity,
      part_id,
      lastSeenLocation,
      trackerName,
      objectTypeId,
      isExpedited,
      src,
      creationTime,
      dueDate
    } = this.state;

    const { filter } = this.props;

    let time = null;
    let date = null;
    if (timeLastUpdated) {
      time = getFormattedDate(timeLastUpdated, 'hh:mm A');
      date = getFormattedDate(timeLastUpdated, 'MMM Do');
    }
    const due = dueDate && getFormattedDate(dueDate, 'MMM Do');

    const borderColor = { 'border-top': `15px solid ${healthColor}` };
    if (filter) {
      const creDate = getFormattedDate(creationTime, 'MMM Do');
      return this.getDetailGridCard(
        borderColor,
        objectTypeId,
        name,
        src,
        trackerName,
        isExpedited,
        part_id,
        lastSeenLocation,
        time,
        date,
        creDate,
        due
      );
    }
    return this.getOverallGridCard(borderColor, quantity, name, objectTypeId, time, date);
  }
}

GridCard.propTypes = {
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired
};
