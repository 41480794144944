import React, { Component } from 'react';
import { Input } from 'mdbreact';
import './ProfileForm.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const Lable = {
  givenName: 'First Name ',
  familyName: 'Last Name ',
  username: 'Username ',
  email: 'Email ',
  phone: 'Phone ',
  jobTitle: 'Position Title ',
  admin: 'Admin '
};

export default class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: {
        givenName: ' Please enter a first name',
        familyName: ' Please enter a last name',
        username: ' Please enter a username with no spaces',
        email: ' Please enter a valid email address',
        phone: ' Please enter a valid phone number'
      },
      placeholder: {
        givenName: ' first name',
        familyName: ' last name',
        username: ' username',
        email: ' email address',
        phone: ' phone number (optional)',
        jobTitle: ' position title (optional)'
      },
      done: false,
      isAdmin: false
    };
    this.clean.bind(this);
  }

  componentDidUpdate() {
    this.clean();
  }

  clean = () => {
    const {clean } = this.props;
    const {done } = this.state;
    if (clean && !done) {
      this.setState({
        givenName: '',
        familyName: '',
        username: '',
        email: '',
        phone: '',
        jobTitle: '',
        done: true
      });
    } else if (!this.props.clean && this.state.done) {
      this.setState({
        done: false
      });
    }
  };

  renderErrorMessage = field => {
    if (this.props.shouldMarkError(field)) {
      return <span className="optional-text-error"> -{this.state.errorMessage[field]}</span>;
    }
  };

  handleIsAdmin = event => {
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    this.handleChange(name, value);
  };

  isDisable = field => {
    if (this.props.disabled) {
      return true;
    }
    if (this.props.editable) {
      return !this.props.editable[field];
    }
    return false;
  };

  handleChange = (id, value) => {
    this.setState({
      [id]: value
    });
    this.props.handleChange(id, value);
  };

  renderInput = (attrName, optional) => {
    return (
      <Input
        disabled={this.isDisable(attrName)}
        value={this.state[attrName]}
        hint={this.props.hint ? this.props.hint[attrName] : this.state.placeholder[attrName]}
        className="input-text"
        label={
          <span>
            {Lable[attrName]} {optional ? <span className="optional-text">- optional</span> : ''}
            {this.renderErrorMessage(attrName)}
          </span>
        }
        labelClass="active"
        id={attrName}
        onInput={event => this.handleChange(event.target.id, event.target.value)}
        onBlur={() => {
          if (this.props.handleBlur) {
            this.props.handleBlur(attrName);
          }
        }}
      />
    );
  };

  render() {
    const { create } = this.props;
    return (
      <div className="profile-fields">
        {this.renderInput('givenName')}
        {this.renderInput('familyName')}
        {this.renderInput('username')}
        {this.renderInput('email')}
        {create && (
        <label className="active">
          <span className="admin-field">Admin</span>
          <input
            className="checkbox"
            name="isAdmin"
            type="checkbox"
            onChange={this.handleIsAdmin}
          />
        </label>
                )        }
        <div className="md-form">
          <label className="active">
            <span>
              {Lable['phone']} {this.renderErrorMessage('phone')}
            </span>
          </label>
          <PhoneInput
            className="phone-input"
            inputClassName="input-text"
            value={this.state.phone}
            disabled={this.isDisable('phone')}
            id="phone"
            country="US"
            displayInitialValueAsLocalNumber={true}
            limitMaxLength={true}
            placeholder={
              this.props.hint ? this.props.hint['phone'] : this.state.placeholder['phone']
            }
            onChange={phoneVal => {
              this.handleChange('phone', phoneVal);
              this.props.handleChange('phone', phoneVal);
            }}
            onBlur={this.props.validatePhoneNumber}
          />
        </div>
        {this.renderInput('jobTitle', true)}
      </div>
    );
  }
}
