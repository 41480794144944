import React, { PureComponent, Fragment } from 'react';
import { Card, CardBody } from 'mdbreact';
import queryString from 'query-string';
import OrderTrackService from '../../../services/OrderTrackService';
import RestService from '../../../services/RestService';
import ConfigurationService from '../../../services/ConfigurationService';
import DepartmentsOrdersGraph from '../../../pages/report/DepartmentsOrdersGraph';
import Overview from './Overview';
import GridView from './GridView';
import DepartmentThresholdGraph from './DepartmentThresholdGraph';
import Style from './css/OrderComponent.module.css';

const HealthMap = {
  Critical: {
    name: 'red',
    value: '#FF3D46'
  },
  Warning: {
    name: 'yellow',
    value: '#F6B045'
  },
  Healthy: {
    name: 'green',
    value: '#62D32E'
  }
};

const loadingCircle = () => {
  return (
    <div className={Style.lds_roller}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default class OrderComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      objects: [],
      health: 'Loading...',
      color: 'black',
      title: 'Overview',
      searchObject: {
        type: null,
        value: null
      }
    };
  }

  async componentDidMount() {
    const searchObject = {
      type: queryString.parse(window.location.search).location ? 'Location' : null,
      value: queryString.parse(window.location.search).location || null
    };

    await this.onLoad(searchObject);
  }

  async onLoad(searchObject) {
    const trackService = await OrderTrackService.build(searchObject);
    const objects = trackService.getTrackDataList();

    const weekly = trackService.getWeeklyStatus();
    const tagMap = trackService.getTrackerMap();
    const filter = !!searchObject.value;

    let deptName = null;
    if (searchObject.type === 'Location' && objects.length > 0) {
      deptName = objects[0].department.department_name;
    }
    const title = `${deptName || `Overall`} Overview`;

    this.setState({
      weeklyStatsLabel1: weekly.weeklyStatsLabel1,
      weeklyStatsLabel2: weekly.weeklyStatsLabel2,
      weeklyStatsLabel3: weekly.weeklyStatsLabel3,
      weeklyStats1: weekly.weeklyStats1,
      weeklyStats2: weekly.weeklyStats2,
      weeklyStats3: weekly.weeklyStats3,
      loading: false,

      objects,
      title,
      filter,
      searchObject,
      tagMap
    });
  }

  getOverviewData = (data, filter) => {
    let criticalCount = 0;
    let warningCount = 0;
    let expeditedCount = 0;
    let totalDepts = 0;
    let totalOrderCount = 0;
    let locationHealth = 'Healthy';
    const location = filter ? 'Department' : 'Plant';
    if (filter) {
      totalOrderCount = data.groupInfo.attributes.count.total.value;
      expeditedCount = data.groupInfo.attributes.count.expedited.value;
      if (data.groupInfo.currentThresholdState === 'warning') locationHealth = 'Warning';
      if (data.groupInfo.currentThresholdState === 'critical') locationHealth = 'Critical';
    } else if (data && data.length > 0) {
      data.forEach(dept => {
        switch (dept.currentThresholdState) {
          case 'warning':
            warningCount += 1;
            break;
          case 'critical':
            criticalCount += 1;
            break;

          default:
            break;
        }
        totalDepts += 1;
        totalOrderCount += dept.totalOrders;
        expeditedCount += dept.numberOfExpeditedOrders;
      });

      const warningPercentage = (warningCount / totalDepts) * 100;
      const criticalPercentage = (criticalCount / totalDepts) * 100;

      if (warningPercentage >= 40 || criticalPercentage >= 20) locationHealth = 'Warning';
      if (warningPercentage >= 80 || criticalPercentage >= 40) locationHealth = 'Critical';
    }
    const overview = {
      overviewDataLabel1: `${location} Health`,
      overviewDataLabel2: 'Total No. of Orders',
      overviewDataLabel3: filter ? 'No. of Expedited Orders' : '',
      overviewData1: locationHealth,
      overviewData2: totalOrderCount,
      overviewData3: filter ? expeditedCount : null,
      color1: HealthMap[locationHealth]
    };

    this.setState({
      overviewDataLabel1: overview.overviewDataLabel1,
      overviewDataLabel2: overview.overviewDataLabel2,
      overviewDataLabel3: overview.overviewDataLabel3,
      overviewData1: overview.overviewData1,
      overviewData2: overview.overviewData2,
      overviewData3: overview.overviewData3,
      color1: overview.color1
    });
  };

  getChartData = async graphData => {
    const { filter } = this.state;
    const departmentNameMap = {};
    let graphTitle = '';

    const placeholderData = {
      departmentName: null,
      plantName: null
    };
    let chartData = null;

    if (filter) {
      placeholderData.deptName = graphData.length > 0 ? graphData[0].groupInfo.name : '';
      chartData = graphData;
    } else {
      const plants = await RestService.get('/plants');
      const plantName = plants[0].plant_name;

      graphTitle = `${plantName} - Department Backlog` || 'Plant Department Backlog';

      const healthColorMap = {};

      chartData = graphData.map(object => {
        const groupInfo = object.groupInfo;
        const count = groupInfo.attributes.count;

        const departmentId = groupInfo.id;
        const departmentName = groupInfo.name;
        const simpleName = groupInfo.simpleName;

        const totalNonExpeditedOrders = count.non_expedited.value;
        const totalExpeditedOrders = count.expedited.value;
        const totalOrders = count.total.value;
        const currentThresholdState = groupInfo.currentThresholdState;

        departmentNameMap[simpleName] = departmentName;
        healthColorMap[departmentName] = HealthMap[currentThresholdState];

        const backlog = {
          departmentId,
          departmentName,
          simpleName,
          numberOfNonExpeditedOrders: totalNonExpeditedOrders,
          numberOfExpeditedOrders: totalExpeditedOrders,
          totalOrders,
          currentThresholdState
        };
        return backlog;
      });

      placeholderData.plantName = plantName;
    }

    this.getOverviewData(chartData, filter);

    this.setState({
      chartData,
      departmentNameMap,
      graphTitle,
      placeholderData
    });
  };

  render() {
    const {
      overviewDataLabel1,
      color1,
      overviewDataLabel2,
      overviewDataLabel3,
      overviewData1,
      overviewData2,
      overviewData3,
      weeklyStats1,
      weeklyStats2,
      weeklyStats3,
      weeklyStatsLabel1,
      weeklyStatsLabel2,
      weeklyStatsLabel3,
      title,
      searchObject,
      tagMap,
      objects,
      filter,
      loading,
      chartData,
      departmentNameMap,
      graphTitle,
      placeholderData
    } = this.state;

    const config = ConfigurationService.getFullConfigurations().preference;

    if (config) {
      for (let i = 0; i < objects.length; i++) {
        let object = objects[i];
        let temp = object[config.PRIMARY_ATTR];
        object[config.PRIMARY_ATTR] = object.object_name;
        object.object_name = temp;
      }
    }
    return (
      <Fragment>
        <Card className={Style.content_holder}>
          <CardBody className={Style.override_padding}>
            <div className={Style.order_backlog_chart}>
              {searchObject.type === 'Location' ? (
                objects && objects.length > 0 && color1 ? (
                  <Fragment>
                    <h4 style={{ textAlign: 'center', fontWeight: 'bold', paddingBottom: 10 }}>
                      Weekly Department Backlog
                    </h4>
                    {objects[0].department.department_id === 'Unknown' ? (
                      <div className={Style.error_message} style={{ paddingTop: 5 }}>
                        {' '}
                        No data available.{' '}
                      </div>
                    ) : (
                      <DepartmentThresholdGraph
                        key={objects[0].department.department_id}
                        domainItemName={objects[0].department.department_name}
                        departmentId={objects[0].department.department_id}
                        departmentHealth={color1.value}
                        hideLegend
                      />
                    )}
                  </Fragment>
                ) : (
                  loadingCircle()
                )
              ) : (
                <Fragment>
                  <h4 style={{ textAlign: 'center', fontWeight: 'bold', paddingBottom: 10 }}>
                    {graphTitle}
                  </h4>
                  <DepartmentsOrdersGraph
                    departmentOrders={chartData && chartData.length > 0 ? chartData : []}
                    departmentNameMap={departmentNameMap}
                  />
                </Fragment>
              )}
            </div>

            <div className={`${Style.overview}`}>
              {!loading ? (
                objects && objects.length > 0 ? (
                  <Overview
                    overviewDataLabel1={overviewDataLabel1}
                    overviewDataLabel2={overviewDataLabel2}
                    overviewDataLabel3={overviewDataLabel3}
                    color1={color1 ? color1.name : ''}
                    overviewData1={overviewData1}
                    overviewData2={overviewData2}
                    overviewData3={overviewData3}
                    weeklyStats1={weeklyStats1}
                    weeklyStats2={weeklyStats2}
                    weeklyStats3={weeklyStats3}
                    weeklyStatsLabel1={weeklyStatsLabel1}
                    weeklyStatsLabel2={weeklyStatsLabel2}
                    weeklyStatsLabel3={weeklyStatsLabel3}
                    title={title}
                  />
                ) : searchObject.value ? (
                  <div className={`${Style.error_message} ${Style.red_text}`}>
                    {' '}
                    Oops! {searchObject.type} - {searchObject.value} does not exist.{' '}
                  </div>
                ) : (
                  <div className={Style.error_message}> No Orders to track. </div>
                )
              ) : (
                loadingCircle()
              )}
            </div>
            <hr className={Style.line} />
            <div className={Style.grid_view}>
              {!loading ? (
                objects ? (
                  <GridView
                    searchObject={searchObject}
                    filter={filter}
                    tagMap={tagMap}
                    objects={objects}
                    callbackFn={this.getChartData}
                    placeholderData={placeholderData}
                    defaultSorting="due_date"
                    config={ConfigurationService.getFullConfigurations()}
                  />
                ) : null
              ) : (
                loadingCircle()
              )}
            </div>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}
