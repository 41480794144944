import React from 'react';
import './AppComponent.css';

export default class AppComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: props.disabled,
      href: props.href,
      displayName: props.displayName,
      imageInfo: props.imageInfo,
      imageSrc: props.imageSrc,
      imageSrcAlt: props.imageSrcAlt
    };

    this.enterApp = this.enterApp.bind(this);
  }

  enterApp() {
    window.location = this.state.href;
  }

  render() {
    return (
      <div className={!this.state.disabled ? 'xemelgo-app clickable' : 'xemelgo-app unavailable'}>
        <div onClick={!this.state.disabled ? this.enterApp : undefined} className="appbox">
          <img
            className={this.state.imageInfo.className}
            src={this.state.imageInfo.src}
            alt={this.state.imageInfo.srcAlt}
          />
        </div>
        <div className="appname">{this.state.displayName}</div>
      </div>
    );
  }
}
