import RestService from './RestService';

export default class TrackedObjectService {
  static async build(thisObject) {
    const getObjectInfo = async () => {
      const trackedObject = await RestService.get(`/tracked-objects/${thisObject}/route`);
      let objectInfoList = {
        object_name: trackedObject.object_name,
        object_id: trackedObject.tracked_object_id,
        object_class: trackedObject.object_class,
        object_type_id: trackedObject.object_type_id,
        is_active: trackedObject.is_active,
        is_expedited: trackedObject.is_expedited,
        tracker_id: trackedObject.tracker_id,
        order_route: trackedObject.routes,
        department: trackedObject.department,
        zone: trackedObject.zone,
        part_id: trackedObject.part_id
      };
      const object = await RestService.get(`/tracked-objects/${thisObject}`);
      const trackerObject = await RestService.get(`/trackers/${trackedObject.tracker_id}`);
      objectInfoList = {
        ...objectInfoList,
        image: object.image,
        tags: object.tags,
        due_date: object.due_date,
        creation_date: object.creation_date,
        plant_id: object.plant_id,
        tracker_serial: trackerObject.tracker_serial
      };
      return objectInfoList;
    };

    const objects = await getObjectInfo();
    return objects;
  }

  static async expediteOrder(checked, id) {
    let data = null;
    await RestService.patch(`/tracked-objects/${id}`, {
      op: 'replace',
      path: '/is_expedited',
      value: checked
    }).then(result => {
      data = result.is_expedited;
    });
    return data;
  }

  static async missingOrder(missing, data) {
    let result = null;
    let { tags } = data;
    if (!tags) {
      tags = {};
    }
    tags.miss_found_status = missing ? 'Missing' : 'Found';
    await RestService.patch(`/tracked-objects/${data.object_id}`, {
      op: 'replace',
      path: '/tags',
      value: tags
    }).then(ret => {
      result = ret.tags.miss_found_status === 'Missing';
    });
    return result;
  }

  static async uploadObjectImage(file, id) {
    const payload = [];
    let imageObject = '';
    await RestService.uploadImage('/images', file, file.type).then(
      result => {
        imageObject = result.data;
      },
      err => {
        alert(`Fail to upload image: ${err}`);
      }
    );

    if (imageObject !== '') {
      payload.image = imageObject;
      payload.push({
        op: 'replace',
        path: '/image',
        value: imageObject
      });
    }

    return await RestService.patch(`/tracked-objects/${id}`, payload).then(
      result => {
        return result;
      },
      err => {
        alert(`Fail to upload image: ${err}`);
      }
    );
  }

  static async updateOrderDetails(object_id, orderPayload) {
    const payload = buildUpdatePayload(orderPayload);
    return RestService.patch(`/tracked-objects/${object_id}`, payload).then(result => {
      return result;
    });
  }
}
const buildUpdatePayload = object => {
  const payload = [];
  Object.keys(object).forEach(key => {
    payload.push({ op: 'replace', path: `/${key}`, value: object[key] });
  });
  return payload;
};
