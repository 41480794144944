import React, { Component, Fragment } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

import './DepartmentsOrdersGraph.css';

export default class DepartmentsOrdersGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maxColumns: 7,
      departmentOrders: [],
      // colors: ['#F62227', '#FFCF0F', '#4D8605', '#E45A00'],
      colors: ['#FF3D46', '#F6B045', '#62D32E', '#F58800'],
      width: window.innerWidth
    };
  }

  componentDidMount() {
    this.setState({
      departmentOrders: this.getSortedData(this.props.departmentOrders)
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // You don’t have to do this check first, but it can help prevent an unneeded render
    if (nextProps.departmentOrders.length !== this.state.departmentOrders.length) {
      this.setState({
        departmentOrders: this.getSortedData(nextProps.departmentOrders)
      });
    }
  }

  getSortedData(data) {
    const { maxColumns } = this.state;
    data.sort((a, b) => (a.totalOrders < b.totalOrders ? 1 : -1));
    return data.slice(0, maxColumns);
  }

  render() {
    const { width, departmentOrders } = this.state;
    const isMobile = width <= 768;

    const getResponsiveContainerAspect = () => {
      if (!isMobile) {
        return 4 / 2;
      }

      return 2.5 / 2;
    };
    return (
      <Fragment>
        <ResponsiveContainer aspect={getResponsiveContainerAspect()} width="100%">
          <BarChart
            data={departmentOrders}
            height={200}
            margin={{ top: 5, right: 10, bottom: 5, left: -35 }}
            isAnimationActive={false}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="simpleName" name="departmentName" />
            <YAxis domain={[0, dataMax => dataMax + 5]} />
            <Tooltip labelFormatter={name => this.props.departmentNameMap[name]} />
            <Legend
              payload={[
                { id: 'Critical', value: 'Critical', type: 'rect', color: this.state.colors[0] },
                { id: 'Warning', value: 'Warning', type: 'rect', color: this.state.colors[1] },
                { id: 'Healthy', value: 'Healthy', type: 'rect', color: this.state.colors[2] },
                {
                  id: 'ExpeditedOrders',
                  value: 'Expedited',
                  type: 'rect',
                  color: this.state.colors[3]
                }
              ]}
              align="center"
            />
            <Bar
              dataKey="numberOfNonExpeditedOrders"
              stackId="a"
              name="Non-Expedited Orders"
              isAnimationActive={false}
            >
              {departmentOrders.map((entry, index) => {
                if (entry.currentThresholdState === 'warning') {
                  return <Cell fill={this.state.colors[1]} key={index} />;
                }
                if (entry.currentThresholdState === 'critical') {
                  return <Cell fill={this.state.colors[0]} key={index} />;
                }
                return <Cell fill={this.state.colors[2]} key={index} />;
              })}
            </Bar>
            <Bar
              dataKey="numberOfExpeditedOrders"
              stackId="a"
              fill={this.state.colors[3]}
              name="Expedited Orders"
            />
          </BarChart>
        </ResponsiveContainer>
      </Fragment>
    );
  }
}
