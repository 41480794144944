import React, { PureComponent, Fragment } from 'react';
import { Chart as DonutChart } from 'react-google-charts';
import { isAbsolute } from 'path';
import PropTypes from 'prop-types';
import './css/Chart.css';

const loadingCircle = () => {
  return (
    <div className="lds-roller">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

/*
 * Chart takes props from InventoryComponent and displays Plant/Location name and DonutChart
 * Reference Google Charts documentation for DonutChart details
 * https://developers.google.com/chart/interactive/docs/gallery/piechart#making-a-donut-chart
 */
export default class Chart extends PureComponent {
  render() {
    const { plant, location, data } = this.props;

    return (
      <Fragment>
        <div className="title-text">
          <p className="title-text">
            {plant}
            {location}
          </p>
          <br />
          <p className="title-separation title-text">Inventory Health</p>
        </div>
        <div className="my-pretty-chart-container">
          <DonutChart
            position={isAbsolute}
            width={400}
            height={270}
            chartType="PieChart"
            loader={loadingCircle()}
            data={data}
            options={{
              titlePosition: 'none',
              legend: { position: 'top', alignment: 'center' },
              chartArea: { left: 0, top: 55, right: 0, bottom: 60 },
              colors: ['#F74446', '#F5B052', '#4298FA', '#61D438'],
              pieSliceText: 'none',
              pieHole: 0.5,
              backgroundColor: { fill: 'transparent' }
            }}
            rootProps={{ 'data-testid': '1' }}
            chartWrapperParams={{ view: { columns: [0, 1] } }}
            chartPackages={['corechart', 'controls']}
          />
        </div>
      </Fragment>
    );
  }
}

Chart.propTypes = {
  plant: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.array).isRequired
};
