export default {
  customerIDtoCustomerMap: {
    '101': 'testCustomerOrder',
    '102': 'testCustomerInventory',
    '103': 'testCustomerInventoryGenerateTrackedObjectsWithTags',
    tg: 'toolgauge',
    kc: 'keycompounding',
    '104': 'testCustomerInventoryGenerateTrackedObjectsWithTagsHex',
    '201': 'gmn',
    gt: 'globaltech',
    demo: 'demo',
    sl: 'slayer',
    '105': 'testCustomerInventoryGenerateTrackedObjectsWithTagsNumerical',
    '111': 'ordertrack',
    in: 'inventoryprod',
    orion: 'orion',
    crane: 'crane',
    andrews: 'andrews',
    kuslers: 'kuslers',
    '100': 'testCustomerOrder2',
    skills: 'skills',
    carlisle: 'carlisle',
    hu: 'testCustomerInventory'
  },

  solutionTypes: {
    order: {
      trackedObjectDataViewerConfiguration: {
        trackedObjectDataViewerPageHeader: 'Orders',
        trackedObjectDataViewerPageDescription: 'Select an order number to see more details'
      },
      manageOrganizationConfiguration: {
        tabs: ['Plant', 'Tags'],
        defaultTab: 0
      },
      searchBarConfiguration: {
        searchBarPlaceHolder: 'Search via order number...'
      }
    },
    inventory: {
      trackedObjectDataViewerConfiguration: {
        trackedObjectDataViewerPageHeader: 'Track',
        trackedObjectDataViewerPageDescription: 'Select an inventory item to see more details',
        trackedObjectDataViewerTableHeader: 'In Stock'
      },
      manageOrganizationConfiguration: {
        tabs: ['Plant', 'Tags', 'Inventory'],
        defaultTab: 0
      },
      searchBarConfiguration: {
        searchBarPlaceHolder: 'Search via inventory object type...'
      }
    },
    inventoryWithNoStock: {
      trackedObjectDataViewerConfiguration: {
        trackedObjectDataViewerPageHeader: 'Track',
        trackedObjectDataViewerPageDescription: 'Select a part to see more details',
        trackedObjectDataViewerTableHeader: 'Parts'
      },
      manageOrganizationConfiguration: {
        tabs: ['Plant', 'Tags', 'Inventory'],
        defaultTab: 0
      },
      searchBarConfiguration: {
        searchBarPlaceHolder: 'Search via inventory object type...'
      }
    },
    asset: {
      trackedObjectDataViewerConfiguration: {
        trackedObjectDataViewerPageHeader: 'Assets',
        trackedObjectDataViewerPageDescription: 'Select an asset to see more details'
      },
      manageOrganizationConfiguration: {
        tabs: ['Plant', 'Tags'],
        defaultTab: 0
      },
      searchBarConfiguration: {
        searchBarPlaceHolder: 'Select an asset to see more details'
      }
    }
  },

  customers: {
    testCustomerOrder: {
      solutionType: 'order',
      optionalColumnHeader: ['Cost'], // comment this out if using for KC
      associationType: 'addTagsWithNumerical',
      rules: ['Low Stock', 'Expedited', 'Arrived At'],
      locationInfo: {
        CONSUME_ZONE: '2d25cf4f-2040-4622-a936-c5011b59e515',
        CONSUME_GATEWAY: '83ac55ce-b9ba-4fef-94cf-70e0679cb0f2'
      },
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://2r03hywfpb.execute-api.us-west-2.amazonaws.com/dev'
        // URL: "http://localhost:3000"
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_OdQJcDVJL',
        APP_CLIENT_ID: '5eqv16defscgprremurmstpsbn',
        IDENTITY_POOL_ID: 'us-west-2:9c0d17af-c675-41db-be31-353b3aa5f40e'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-dev'
      }
    },

    testCustomerOrder2: {
      solutionType: 'asset',
      optionalColumnHeader: ['Cost'], // comment this out if using for KC
      rules: ['Low Stock', 'Missing', 'Arrived At', 'Expired'],
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://2r03hywfpb.execute-api.us-west-2.amazonaws.com/dev'
        // URL: "http://localhost:3000"
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_OdQJcDVJL',
        APP_CLIENT_ID: '5eqv16defscgprremurmstpsbn',
        IDENTITY_POOL_ID: 'us-west-2:9c0d17af-c675-41db-be31-353b3aa5f40e'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-dev'
      }
    },

    testCustomerInventory: {
      solutionType: 'inventory',
      rules: ['Low Stock', 'Expedited', 'Overstock'],
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://2r03hywfpb.execute-api.us-west-2.amazonaws.com/dev'
        // URL: "http://localhost:3000"
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_OdQJcDVJL',
        APP_CLIENT_ID: '5eqv16defscgprremurmstpsbn',
        IDENTITY_POOL_ID: 'us-west-2:9c0d17af-c675-41db-be31-353b3aa5f40e'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-dev'
      }
    },

    testCustomerInventoryGenerateTrackedObjectsWithTags: {
      solutionType: 'inventory',
      rules: ['Low Stock', 'Expedited', 'Overstock'],
      associationType: 'generateTrackedObjects',
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://2r03hywfpb.execute-api.us-west-2.amazonaws.com/dev'
        // URL: "http://localhost:3000"
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_OdQJcDVJL',
        APP_CLIENT_ID: '5eqv16defscgprremurmstpsbn',
        IDENTITY_POOL_ID: 'us-west-2:9c0d17af-c675-41db-be31-353b3aa5f40e'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-dev'
      }
    },

    testCustomerInventoryGenerateTrackedObjectsWithTagsHex: {
      solutionType: 'inventory',
      rules: ['Low Stock', 'Expedited', 'Overstock'],
      associationType: 'generateTrackedObjectsHex',
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://2r03hywfpb.execute-api.us-west-2.amazonaws.com/dev'
        // URL: "http://localhost:3000"
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_OdQJcDVJL',
        APP_CLIENT_ID: '5eqv16defscgprremurmstpsbn',
        IDENTITY_POOL_ID: 'us-west-2:9c0d17af-c675-41db-be31-353b3aa5f40e'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-dev'
      }
    },

    testCustomerInventoryGenerateTrackedObjectsWithTagsNumerical: {
      solutionType: 'inventory',
      rules: ['Low Stock', 'Expedited', 'Overstock'],
      associationType: 'generateTrackedObjectsNumerical',
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://2r03hywfpb.execute-api.us-west-2.amazonaws.com/dev'
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_OdQJcDVJL',
        APP_CLIENT_ID: '5eqv16defscgprremurmstpsbn',
        IDENTITY_POOL_ID: 'us-west-2:9c0d17af-c675-41db-be31-353b3aa5f40e'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-dev'
      }
    },

    gmn: {
      solutionType: 'order',
      rules: ['Expedited'],
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://5xdu46xhza.execute-api.us-west-2.amazonaws.com/gmn-prod'
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_67cHfYmab',
        APP_CLIENT_ID: '4a8bvjb0g2em7rclmbjb949kkv',
        IDENTITY_POOL_ID: 'us-west-2:2ef90ed2-000c-4ccd-9b72-fe5dea941bfd'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-dev'
      }
    },
    toolgauge: {
      solutionType: 'inventoryWithNoStock',
      rules: ['Low Stock'],
      associationType: 'generateTrackedObjectsHex',
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://55p5rhi1dc.execute-api.us-west-2.amazonaws.com/tg-prod'
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_9RBnra41g',
        APP_CLIENT_ID: '792n94ct1pk9m4m431ihu4v47p',
        IDENTITY_POOL_ID: 'us-west-2:2ef90ed2-000c-4ccd-9b72-fe5dea941bfd'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-tg-prod'
      }
    },
    keycompounding: {
      solutionType: 'inventory',
      rules: ['Low Stock', 'Overstock'],
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://cqrn7zlkx0.execute-api.us-west-2.amazonaws.com/kc-prod'
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_YRnhOnwob',
        APP_CLIENT_ID: '4ae5lvue3c5drcg21e0828rjon',
        IDENTITY_POOL_ID: 'us-west-2:2ef90ed2-000c-4ccd-9b72-fe5dea941bfd'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-kc-prod'
      }
    },
    slayer: {
      solutionType: 'inventory',
      rules: ['Low Stock', 'Overstock'],
      associationType: 'generateTrackedObjectsHex',
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://lty3kfzkw1.execute-api.us-west-2.amazonaws.com/slayer'
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_hTCP6zAvf',
        APP_CLIENT_ID: '74lpo8iluqgehc9cd7ipn603lb',
        IDENTITY_POOL_ID: 'us-west-2:2ef90ed2-000c-4ccd-9b72-fe5dea941bfd'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-slayer'
      }
    },
    globaltech: {
      solutionType: 'order',
      rules: ['Expedited'],
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://xbbxh7i6i3.execute-api.us-west-2.amazonaws.com/gt'
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_rZU5qO9Ox',
        APP_CLIENT_ID: '17s7rjihd3eu750bkql7koc4ev',
        IDENTITY_POOL_ID: 'us-west-2:2ef90ed2-000c-4ccd-9b72-fe5dea941bfd'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-gt'
      }
    },
    demo: {
      solutionType: 'inventory',
      rules: ['Low Stock', 'Overstock'],
      associationType: 'generateTrackedObjectsHex',
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://55p5rhi1dc.execute-api.us-west-2.amazonaws.com/tg-prod'
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_9RBnra41g',
        APP_CLIENT_ID: '792n94ct1pk9m4m431ihu4v47p',
        IDENTITY_POOL_ID: 'us-west-2:2ef90ed2-000c-4ccd-9b72-fe5dea941bfd'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-tg-prod'
      }
    },
    ordertrack: {
      solutionType: 'order',
      associationType: 'addTagsWithNumerical',
      rules: ['Expedited'],
      locationInfo: {
        CONSUME_ZONE: 'a7a247ba-8280-480d-9807-4424a3807e9a',
        CONSUME_GATEWAY: '5f97ad07-41da-46cf-8144-a8bdebe38d63'
      },
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://5bocweodx4.execute-api.us-west-2.amazonaws.com/ordertrack'
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_DlyuROFMY',
        APP_CLIENT_ID: '51ti1mjq0ior90rh2odeetkg4h',
        IDENTITY_POOL_ID: 'us-west-2:9c0d17af-c675-41db-be31-353b3aa5f40e'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-ordertrack'
      }
    },
    inventoryprod: {
      solutionType: 'inventory',
      rules: ['Low Stock', 'Overstock'],
      associationType: 'generateTrackedObjectsHex',
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://ebddmfz8wj.execute-api.us-west-2.amazonaws.com/in'
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_QQBJ21KlF',
        APP_CLIENT_ID: '7j9p003mnnvmqictdmd605l4hr',
        IDENTITY_POOL_ID: 'us-west-2:9c0d17af-c675-41db-be31-353b3aa5f40e'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-in'
      }
    },
    orion: {
      solutionType: 'order',
      associationType: 'addTagsWithNumerical',
      rules: ['Expedited', 'Arrived At'],
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://w4cupaaw5a.execute-api.us-west-2.amazonaws.com/orion-prod'
      },
      locationInfo: {
        ADD_ZONE: 'aac40d65-4c79-46e7-a2d9-4c71f60d62dd',
        CONSUME_ZONE: 'bb7ca6db-24ee-4512-bced-b43436fb4050',
        CONSUME_GATEWAY: '1482298e-3e23-43a1-93df-cce1bd76360e'
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_x2LiT5MDY',
        APP_CLIENT_ID: '1mafq2rnjl449ars2mdqv0lgev',
        IDENTITY_POOL_ID: 'us-west-2:2ef90ed2-000c-4ccd-9b72-fe5dea941bfd'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-orion-prod'
      },
      preference: {
        PRIMARY: 'Part Number',
        PRIMARY_ATTR: 'part_id',
        SECONDARY: 'Order Number',
        SECOND_ATTR: 'object_name'
      }
    },
    crane: {
      solutionType: 'asset',
      rules: ['Missing', 'Expired'],
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://at8qse4xnc.execute-api.us-west-2.amazonaws.com/crane-prod/'
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_fdSb7vM9l',
        APP_CLIENT_ID: '5iibdp9apu0pvgjfer1tb11jk9',
        IDENTITY_POOL_ID: 'us-west-2:2ef90ed2-000c-4ccd-9b72-fe5dea941bfd'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-crane-prod'
      }
    },
    kuslers: {
      solutionType: 'inventory',
      optionalColumnHeader: ['Cost'],
      rules: ['Low Stock', 'Overstock'],
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://tgl52m4ajg.execute-api.us-west-2.amazonaws.com/kuslers-prod'
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_CTzqqwQiL',
        APP_CLIENT_ID: '7cc0isri64ltub2q786pp9lbar',
        IDENTITY_POOL_ID: 'us-west-2:2ef90ed2-000c-4ccd-9b72-fe5dea941bfd'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-kuslers-prod'
      }
    },
    andrews: {
      solutionType: 'order',
      associationType: 'addTagsWithNumerical',
      rules: ['Expedited', 'Arrived At'],
      locationInfo: {
        CONSUME_ZONE: '7df344ce-5f52-4e3e-8508-c83086947d93',
        CONSUME_GATEWAY: '7fc8907a-600d-45f2-9a22-bd9a107ec3b6'
      },
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://pod971499a.execute-api.us-west-2.amazonaws.com/andrews'
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_9KApvoMik',
        APP_CLIENT_ID: '2i79q0pnu6ondbujbhv4jnp2mm',
        IDENTITY_POOL_ID: 'us-west-2:2ef90ed2-000c-4ccd-9b72-fe5dea941bfd'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-andrews'
      }
    },
    skills: {
      solutionType: 'asset',
      associationType: 'addTagsWithNumerical',
      rules: ['Missing'],
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://ha9lq2avih.execute-api.us-west-2.amazonaws.com/skills'
      },
      locationInfo: {
        ADD_ZONE: '4361d45a-0d8d-47a0-8442-cad94a70dc77'
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_3XeWTAQSt',
        APP_CLIENT_ID: '3cgvk6cnhf28s0qmpts3b1s7c9',
        IDENTITY_POOL_ID: 'us-west-2:2ef90ed2-000c-4ccd-9b72-fe5dea941bfd'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-skills'
      }
    },
    carlisle: {
      solutionType: 'asset',
      associationType: 'default',
      rules: ['Missing', 'Expired'],
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://p8wxq87opd.execute-api.us-west-2.amazonaws.com/carlisle'
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_aM037Fxau',
        APP_CLIENT_ID: 'er2pottu40ju5u3vdra2v6pm8',
        IDENTITY_POOL_ID: 'us-west-2:2ef90ed2-000c-4ccd-9b72-fe5dea941bfd'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-carlisle'
      }
    },

    hu: {
      solutionType: 'inventory',
      optionalColumnHeader: ['Cost'],
      rules: ['Low Stock', 'Overstock'],
      apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://2r03hywfpb.execute-api.us-west-2.amazonaws.com/dev'
      },
      cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_oI77raX3B',
        APP_CLIENT_ID: '2qusr8fiu3lr4b2gglu4fjbbj7',
        IDENTITY_POOL_ID: 'us-west-2:2ef90ed2-000c-4ccd-9b72-fe5dea941bfd'
      },
      s3: {
        REGION: 'us-west-2',
        URL: 'https://s3-us-west-2.amazonaws.com/image-location-service-dev'
      }
    }
  }
};
