import RestService from './RestService';

export default class AnalysisService {
  constructor(promiseParam) {
    if (typeof promiseParam === 'undefined') {
      throw new Error('Cannot be called directly');
    }

    this.resourceArray = promiseParam;
  }

  static async build(thisObject) {
    return await RestService.get(`/tracked-objects/active?object_type_id=${thisObject}`).then(
      async objects => {
        return new AnalysisService([...objects]);
      },
      err => {
        throw err;
      }
    );
  }

  async getObjectTypeById(target_id) {
    return await RestService.get(`/tracked-object-types/${target_id}`);
  }

  getArray() {
    return this.resourceArray;
  }

  getAggregateLocations() {
    const locations = [];
    this.resourceArray.forEach(object => {
      if (locations.find(e => e.department_name === object.department.department_name)) {
        locations.find(e => e.department_name === object.department.department_name).count++;
      } else {
        locations.push({
          department_name: object.department.department_name,
          count: 1
        });
      }
    });

    return locations;
  }

  getAggregateCount() {
    let count = 0;
    this.resourceArray.getAggregateLocations.forEach(object => {
      count += object.count;
    });

    return count;
  }

  async getLastUpdatedTime() {
    const times = [];
    this.resourceArray.forEach(object => {
      const timeStamp = object.published_timestamp || object.publishedTimestamp;
      times.push(timeStamp);
    });
    await RestService.get('/analytics/last-update/tracked-object-types').then(
      async objects => {
        if (this.resourceArray.length > 0) {
          const lastUpdateTime = objects.find(
            e => e.object_type_id === this.resourceArray[0].object_type_id
          );
          if (lastUpdateTime) {
            times.push(lastUpdateTime.event_timestamp.published_timestamp);
          }
        }
      },
      err => {
        console.log(err);
      }
    );
    return times.length > 0 ? new Date(Math.max.apply(null, times)) : undefined;
  }
}
