import React, { Fragment, Component } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Surface,
  Symbols,
  ReferenceLine
} from 'recharts';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'mdbreact';
import './HistoricalBackLogGraph.css';
import RestService from '../../services/RestService';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import './DepartmentsOrdersGraph.css';

import HistoricalBacklogDataLoader from './domains/HistoricalBacklogDataLoader';

/**
 * Since this component is used for two very distinct domain object, we use this map to generalize its field names
 * @type {{"tracked-object-types": {name: string, id: string, graphType: string}, departments: {name: string, id: string, graphType: string}}}
 */
const DomainPropertyMap = {
  'tracked-object-types': {
    name: 'object_type_name',
    id: 'object_type_id',
    graphType: 'histogram-by-type'
  },

  departments: {
    name: 'department_name',
    id: 'department_id',
    graphType: 'histogram-by-department'
  }
};

/**
 * These are the field names which are used internally for housekeeping
 * @type {{LastUpdatedTimestamp: string, Active: string, Data: string, ColorHex: string, Entries: string}}
 */
const FieldNames = {
  LastUpdatedTimestamp: '_last_updated_timestamp',
  Active: '_active',
  Data: '_data',
  ColorHex: '_color_hex',
  Entries: '_entries'
};

/**
 * Sort item based on the passed in function
 * @param data
 * @param getSortingValueFn
 * @return {*}
 */
function sortItems(data, getSortingValueFn) {
  data = data.sort((a, b) => {
    const textA = getSortingValueFn(a);
    const textB = getSortingValueFn(b);
    return textA.localeCompare(textB);
  });
  return data;
}

function hashCode(s) {
  let a = 1,
    c = 0,
    h,
    o;
  if (s) {
    a = 0;
    for (h = s.length - 1; h >= 0; h--) {
      o = s.charCodeAt(h);
      a = ((a << 6) & 268435455) + o + (o << 14);
      c = a & 266338304;
      a = c !== 0 ? a ^ (c >> 21) : a;
    }
  }
  return String(a);
}

/**
 * Add '0' in front of hex string if it does not meet the length requirement.
 *  This happens sometimes when the leading digit is 0 and when converting to string,
 *  the leading 0 is omitted
 * @param hex
 * @param maxLength
 * @return {*}
 */
function padHex(hex, maxLength) {
  let paddedHex = hex;
  if (hex.length < maxLength) {
    for (let i = 0; i < maxLength - hex.length; i++) {
      paddedHex = `0${paddedHex}`;
    }
  }

  return paddedHex;
}

// Convert an int to hexadecimal with a max length of six characters.
function intToARGB(i) {
  let hex =
    ((i >> 24) & 0xff).toString(16) +
    ((i >> 16) & 0xff).toString(16) +
    ((i >> 8) & 0xff).toString(16) +
    (i & 0xff).toString(16);

  // guard against the case when leading 0 is stripped.
  hex = padHex(hex, 6);

  return hex.substring(0, 6);
}

function toHexColour(string) {
  return `#${intToARGB(hashCode(string))}`;
}

/**
 * Parse the entry object to just obtains the data and graph title
 * @param entries {[LineGraphDataEntry]}
 * @return {graphTitle: string, dataPoints: [{}]}
 */
function parseEntries(entries) {
  let graphTitle = 'No Data';
  if (entries.length > 0) {
    graphTitle = `${entries[0].timestamp} - ${entries[entries.length - 1].timestamp}`;
  }

  const dataPoints = entries.map(entry => entry.data);
  return {
    graphTitle,
    dataPoints
  };
}

async function executeActionsInSync(list, action) {
  for (let index in list) {
    const item = list[index];
    await action(item);
  }
}

export default class HistoricalBackLogGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth,
      domain: this.props.domain ? this.props.domain : 'departments',
      hideLegend: this.props.hideLegend,
      deptThresholds: this.props.deptThresholds,
      graphTitle: '',
      categories: ['day', 'week', 'month', 'quarter', 'year'],
      activeCategory: 'day',
      allItems: [],
      historicalDataMap: null,
      disabledItemNames: []
    };

    this.propertyNames = DomainPropertyMap[this.state.domain];
    this.dataLoader = new HistoricalBacklogDataLoader(
      `/tracked-objects/${this.propertyNames.graphType}`
    );

    this.handleToggleFilterItem = this.handleToggleFilterItem.bind(this);
    this.handleCategoryChanged = this.handleCategoryChanged.bind(this);
    this.setSelectAll = this.setSelectAll.bind(this);
  }

  async init() {
    let items = await RestService.get(`/${this.state.domain}`);

    // sort them by names
    items = sortItems(items, item => item[this.propertyNames.name]);

    // assign a unique color code for each
    items.forEach(item => {
      const itemName = item[this.propertyNames.name];
      item[FieldNames.ColorHex] = toHexColour(item[this.propertyNames.name]);
      console.log(`=> Item ${itemName} has color code ${item[FieldNames.ColorHex]}`);
    });

    // make the first item active
    if (items && items[0]) {
      items[0][FieldNames.Active] = true;
    }

    let dataPoints = [];
    let graphTitle = 'No Data';
    const activeTimeSpanType = this.state.categories[0];

    // fetch data for the first default item
    if (items && Array.isArray(items) && items.length > 0) {
      const item = items[0];
      const itemId = item[this.propertyNames.id];
      const timeSpanType = activeTimeSpanType;

      await this.dataLoader.loadData(itemId, timeSpanType);
      const entries = this.dataLoader.getData(timeSpanType);

      const dataInfo = parseEntries(entries);
      graphTitle = dataInfo.graphTitle;
      dataPoints = dataInfo.dataPoints;
    }

    this.setState({
      allItems: items,
      activeCategory: activeTimeSpanType,
      dataPoints,
      graphTitle
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.init().then(_ => {
      console.log(`=> Initialized`);
    });
  }

  /**
   * set all items either active or inactive
   * @param select {boolean}
   */
  setSelectAll(select) {
    // set all items to the desired state
    const allItems = this.state.allItems.map(item => {
      item[FieldNames.Active] = select;
      return item;
    });

    // update for rendering
    this.setState({
      allItems
    });

    if (!select) {
      return;
    }

    // fetch data asynchronously so it would not block page from rendering
    const activeCategory = this.state.activeCategory;
    const context = this;
    this.state.allItems.forEach(item => {
      const itemId = item[this.propertyNames.id];
      this.dataLoader.loadData(itemId, activeCategory).then(_ => {
        const entries = this.dataLoader.getData(activeCategory);
        const { graphTitle, dataPoints } = parseEntries(entries);
        context.setState({
          graphTitle,
          dataPoints
        });
      });
    });
  }

  /**
   * handle resize of graph
   */
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  /**
   * Switch category and fetch only those that are currently active
   * @param key
   * @return {Promise<void>}
   */
  async handleCategoryChanged(key) {
    const category = this.state.categories[key];

    const allItems = [...this.state.allItems];
    const filterActiveItems = allItems.filter(item => item[FieldNames.Active]);

    const context = this;
    const itemNames = [];
    executeActionsInSync(filterActiveItems, async item => {
      const itemId = item[this.propertyNames.id];
      const itemName = item[this.propertyNames.name];
      itemNames.push(itemName);

      await this.dataLoader.loadData(itemId, category).then(_ => {
        const entries = this.dataLoader.getData(category);
        const { graphTitle, dataPoints } = parseEntries(entries);

        context.setState({
          graphTitle,
          dataPoints
        });
      });
    }).then(() => {
      console.log(`Complete loading all data for ${itemNames}`);
    });

    this.setState({
      activeCategory: category
    });
  }

  /**
   * Fetch data for those that are set to active state
   * @param itemId
   */
  handleToggleFilterItem = async itemId => {
    const context = this;
    const category = this.state.activeCategory;
    await this.dataLoader.loadData(itemId, category).then(_ => {
      const entries = this.dataLoader.getData(category);
      const { dataPoints, graphTitle } = parseEntries(entries);

      context.setState({
        dataPoints,
        graphTitle
      });
    });

    const allItems = this.state.allItems.map(item => {
      if (item[this.propertyNames.id] === itemId) {
        item[FieldNames.Active] = !item[FieldNames.Active];
      }
      return item;
    });

    this.setState({ allItems });
  };

  renderCustomizedLegend = () => {
    let allItems = this.state.allItems;
    return (
      <Dropdown className="customized-legend" multiple>
        <DropdownToggle nav caret className="dropdown-action">
          Filter Items
        </DropdownToggle>
        <DropdownMenu right>
          <p className="select" onClick={() => this.setSelectAll(true)}>
            Select All
          </p>
          <p className="select" onClick={() => this.setSelectAll(false)}>
            Deselect All
          </p>
          {allItems.map((item, index) => {
            const domainItemName = item[this.propertyNames.name];
            const itemId = item[this.propertyNames.id];
            const color = item[FieldNames.ColorHex];
            const disabled = !item[FieldNames.Active];
            const style = {
              marginRight: 10,
              color: disabled ? '#AAA' : '#000',
              width: '100%'
            };

            return (
              <DropdownItem key={index} toggle={false}>
                <span
                  className="legend-item"
                  onClick={() => this.handleToggleFilterItem(itemId)}
                  style={style}
                >
                  <Surface width={20} height={10}>
                    <Symbols
                      cx={5}
                      cy={5}
                      type="square"
                      size={100}
                      stroke={color}
                      fill={disabled ? '#FFF' : color}
                    />
                  </Surface>
                  <span>{domainItemName}</span>
                </span>
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    );
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 768;

    let returnLegend = () => {
      if (!this.state.hideLegend) {
        return (
          <Legend
            layout={isMobile ? 'horizontal' : 'vertical'}
            verticalAlign={isMobile ? 'bottom' : 'top'}
            align={isMobile ? 'center' : 'right'}
            content={this.renderCustomizedLegend}
            key="legend"
          />
        );
      }
    };

    let getResponsiveContainerAspect = () => {
      if (!isMobile) {
        return 4 / 2;
      }

      return 1 / 2;
    };

    return (
      <Fragment>
        <div className="historical-graph">
          {this.state.allItems.length !== 0 && this.state.dataPoints && (
            <ResponsiveContainer aspect={getResponsiveContainerAspect()} width="100%">
              <LineChart
                data={Object.values(this.state.dataPoints)}
                margin={{ top: 5, right: 10, bottom: 5, left: -35 }}
              >
                {' '}
                {this.state.allItems
                  .filter(item => item[FieldNames.Active])
                  .map(function(item, index) {
                    let domainItemName = item[this.propertyNames.name];
                    const itemId = item[this.propertyNames.id];
                    return (
                      <Line
                        key={index}
                        type="monotone"
                        dataKey={itemId}
                        stroke={item[FieldNames.ColorHex]}
                        strokeWidth="1.5"
                        name={domainItemName}
                        isAnimationActive={false}
                        dot={false}
                      />
                    );
                  }, this)}
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="timestamp" tick={false} />
                <YAxis />
                <Tooltip />
                {returnLegend()}
                {this.state.deptThresholds && (
                  <ReferenceLine
                    y={+this.state.deptThresholds.warning}
                    alwaysShow={true}
                    stroke="#ffc107"
                    label={{ value: 'Warning', position: 'insideTopRight' }}
                    strokeDasharray="3 3"
                  />
                )}
                {this.state.deptThresholds && (
                  <ReferenceLine
                    y={+this.state.deptThresholds.critical}
                    alwaysShow={true}
                    stroke="red"
                    label={{ value: 'Critical', position: 'insideTopRight' }}
                    strokeDasharray="3 3"
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
        <div>
          <div className="time-title"> {this.state.graphTitle} </div>
        </div>
        <ToggleButtonGroup
          className="historical-graph-toolbar"
          type="radio"
          name="filter"
          defaultValue={0}
          onChange={this.handleCategoryChanged}
        >
          <ToggleButton bsClass="tabs" value={0}>
            1D
          </ToggleButton>
          <ToggleButton bsClass="tabs" value={1}>
            1W
          </ToggleButton>
          <ToggleButton bsClass="tabs" value={2}>
            1M
          </ToggleButton>
          <ToggleButton bsClass="tabs" value={3}>
            3M
          </ToggleButton>
          <ToggleButton bsClass="tabs" value={4}>
            1Y
          </ToggleButton>
        </ToggleButtonGroup>
      </Fragment>
    );
  }
}
