import React, { Component, Fragment } from 'react';
import { Alert } from 'react-bootstrap';

import NavbarHeader from 'pages/header/NavbarHeader';

import './TrackObjectNotFoundPage.css';

export default class TrackObjectNotFoundPage extends Component {
  render() {
    return (
      <Fragment>
        <NavbarHeader />
        <Alert bsStyle="danger">
          <h4>Something's not quite right!</h4>
          <p>{this.props.error.message}</p>
        </Alert>
      </Fragment>
    );
  }
}
