import React, { Component, Fragment } from 'react';
import { CardBody } from 'mdbreact';
import Loading from 'components/loading/LoadingWord';
import DataTableComponent from 'components/DataTableComponent/DataTableComponent';
import ObjectTypeService from 'services/ObjectTypeService';
import AddModalMultipleForm from 'components/AddModalMultipleForm';
import EditModalMultipleForm from 'components/EditModalMultipleForm';
import DeleteActionRenderer from 'components/DeleteActionRenderer';
import RestService from '../../services/RestService';
import EditActionRenderer from 'components/EditActionRenderer';

export default class ManageInventory extends Component {
  constructor(props) {
    super(props);

    this.addHandler = this.addHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.editHandler = this.editHandler.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.renderDeleteFunction = this.renderDeleteFunction.bind(this);
    this.gatherInfo = this.gatherInfo.bind(this);

    this.state = {
      loading: true,
      gridOptions: {
        defaultColDef: {
          comparator: function(a, b) {
            if (typeof a === 'string') {
              return a.localeCompare(b);
            } else {
              return a - b;
            }
          }
        },
        suppressPropertyNamesCheck: true
      },
      overallActions: [
        {
          name: '+ Item',
          action: this.addHandler,
          className: 'default-button'
        }
      ],
      cellClickAction: [
        {
          field: 'Edit',
          actionHandler: this.editHandler,
          renderer: EditActionRenderer,
          rendererName: 'editActionRenderer',
          width: 60
        },
        {
          field: 'Delete',
          actionHandler: this.deleteHandler,
          renderer: this.renderDeleteFunction,
          rendererName: 'renderDeleteFunction',
          width: 60
        }
      ]
    };
  }

  async deleteHandler(params) {
    await this.state.provider.deleteItem(params);
    this.setState({
      data: this.state.provider.getArray()
    });
  }

  renderDeleteFunction(params) {
    return (
      <DeleteActionRenderer
        id={params.data.objectTypeId}
        headerName={'Item'}
        gatherInfo={this.gatherInfo}
      />
    );
  }

  async gatherInfo(id) {
    let objectToDelete = await RestService.get('/tracked-object-types/' + id);
    let arrangedDisplayInfo = {
      'Item Name': objectToDelete.object_type_name,
      'Item ID': objectToDelete.object_type_id
    };
    let arrangedDeleteInfo = {
      objectTypeId: objectToDelete.object_type_id,
      objectTypeName: objectToDelete.object_type_name
    };
    let arrangedData = {
      display_info: arrangedDisplayInfo,
      delete_info: arrangedDeleteInfo,
      confirm_function: this.deleteHandler
    };
    return arrangedData;
  }

  async UNSAFE_componentWillMount() {
    let build = await ObjectTypeService.build();
    let sort = [];
    let columnDefs = build.getSchema().map(schemaItem => {
      if (schemaItem.sort) {
        sort.push({
          colId: schemaItem.param_name,
          sort: schemaItem.sort
        });
      }
      return {
        headerName: schemaItem.display_name,
        field: schemaItem.param_name,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        }
      };
    });

    this.setState({
      provider: build,
      data: build.getArray(),
      columnDefs: [...columnDefs],
      sort: sort,
      loading: false
    });
  }

  addHandler() {
    this.setState({
      showAddModal: true
    });
  }

  editHandler(params) {
    this.setState({
      partData: params,
      showEditModal: true
    });
  }

  handleModalClose() {
    this.setState({
      partData: null,
      showAddModal: false,
      data: this.state.provider.getArray(),
      submitting: false,
      showEditModal: false
    });
  }

  render() {
    let addModal = () => {
      return (
        <AddModalMultipleForm provider={this.state.provider} handleClose={this.handleModalClose} />
      );
    };

    let editModal = () => {
      return (
        <EditModalMultipleForm
          provider={this.state.provider}
          handleClose={this.handleModalClose}
          placeholder={this.state.partData}
        />
      );
    };

    return (
      <Fragment>
        <div>{this.state.showAddModal && addModal()}</div>
        <div>{this.state.showEditModal && editModal()}</div>
        <CardBody id="main-card-body">
          <div className="title">
            <h2>Manage Inventory</h2>
            <div>Register inventory items</div>
          </div>

          <div className="page-data-table">
            {this.state.loading ? (
              <Loading />
            ) : (
              <DataTableComponent
                tableName="Items"
                columnDefs={this.state.columnDefs}
                rowData={this.state.data}
                sort={this.state.sort}
                gridOptions={this.state.gridOptions}
                overallActions={this.state.overallActions}
                cellClickAction={this.state.cellClickAction}
                searchBar={true}
              />
            )}
          </div>
        </CardBody>
      </Fragment>
    );
  }
}
