import React from 'react';
import RestService from '../../services/RestService';
import TabbedSectionComponent from '../../components/vertical-tab-with-contents/TabbedSectionComponent';
import TabWithSwitchControlComponent from '../../components/vertical-tab-with-contents/TabWithSwitchControlComponent';
import './RuleConfigurationSection.css';
import AlertService, { ruleInfoMap, temporaryComponent } from '../../services/AlertService';

function generateTabName(tabContent, className, setActiveComponentFn) {
  let tabName = tabContent.name;
  let component = tabContent.component;

  let id = tabContent.name + '-subscription-switch';
  return (
    <TabWithSwitchControlComponent
      key={id}
      tabName={tabName}
      toggleSwitchFn={tabContent.notification.toggleSubscribe}
      isOn={tabContent.notification.subscribed}
      className={className}
      handleOnClick={() => setActiveComponentFn(tabName, component)}
    />
  );
}

export default class RuleConfigurationSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rules: props.rules,
      ruleNotificationMap: {}
    };
  }

  componentDidMount() {
    let context = this;
    AlertService.fetchRules().then(result => {
      let ruleNotificationMap = result.ruleNotificationMap;
      let notificationFeatureEnabled = result.notificationFeatureEnabled;

      let tabs = [];
      for (let ruleName in ruleNotificationMap) {
        const rule = ruleNotificationMap[ruleName];
        const renderComponent = ruleInfoMap[ruleName].component;

        const tabContent = {
          name: ruleName,
          component: renderComponent ? renderComponent(rule.ruleId) : temporaryComponent,
          notification: {
            ruleId: rule.ruleId,
            available: notificationFeatureEnabled,
            subscribed: rule.enabled,
            eventId: rule.eventId,
            toggleSubscribe: async function(on) {
              let ruleId = rule.ruleId;

              let settings = await RestService.put(`/rules/${ruleId}/notification-settings`, {
                enabled: on
              });
              this.subscribed = settings.enabled;
            }
          }
        };

        tabs.push(tabContent);
      }

      context.setState({
        tabContents: tabs,
        notificationFeatureEnabled: notificationFeatureEnabled
      });
    });
  }

  render() {
    return (
      <div className="rule-configuration-section">
        <div className="title">
          <h2>Rules Configuration</h2>
          <div>Set up rules to send to notifications when an event has been triggered</div>
        </div>
        <TabbedSectionComponent
          tabContents={this.state.tabContents}
          className={'rule-detail-tab'}
          generateTabNameFn={generateTabName}
        />
      </div>
    );
  }
}
