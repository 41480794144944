import React, { Component, Fragment } from 'react';
import './HomePage.css';
import { Card, CardBody, CardTitle } from 'mdbreact';
import { getHomePageImage, getCustomerLogo } from 'common/Utilities';

import AppComponent from 'components/xemelgo-app/AppComponent';
import AuthService from '../../services/AuthService';
import ConfigurationService from '../../services/ConfigurationService';

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sessionInfo: undefined
    };
  }

  importAll(r) {
    const images = {};
    r.keys().forEach(item => {
      images[item.replace('./', '')] = r(item);
    });
    return images;
  }

  render() {
    const images = this.importAll(require.context('../../img', false, /\.(png)$/));

    const sessionInfo = AuthService.getSessionInfo();
    const { username } = sessionInfo;
    const { isSuperAdmin } = sessionInfo;

    const apps = [
      {
        href: '/tracked-objects',
        displayName: 'Track',
        imageInfo: {
          src: images['track_icon.png'],
          srcAlt: 'tracked-objects'
        },
        hidden:
          ConfigurationService.getCustomerName() === 'kuslers' ||
          ConfigurationService.getCustomerName() === 'testCustomerInventory'
      },
      {
        href: '/reports',
        displayName: 'Reports',
        imageInfo: {
          src: images['backlog_icon.png'],
          srcAlt: 'reports'
        },
        disabled: ConfigurationService.getSolutionType() === 'asset',
        hidden:
          ConfigurationService.getCustomerName() === 'kuslers' ||
          ConfigurationService.getCustomerName() === 'carlisle' ||
          ConfigurationService.getCustomerName() === 'testCustomerInventory'
      },

      {
        href: '/alerts',
        displayName: 'Alerts',
        imageInfo: {
          src: images['alerts_icon.png'],
          srcAlt: 'alerts'
        },
        hidden:
          ConfigurationService.getCustomerName() === 'kuslers' ||
          ConfigurationService.getCustomerName() === 'testCustomerInventory'
      },

      {
        href: '/reports',
        displayName: 'Messaging',
        imageInfo: {
          src: images['message_icon.png'],
          srcAlt: 'build-reports'
        },
        disabled: true,
        hidden:
          ConfigurationService.getCustomerName() === 'keycompounding' ||
          ConfigurationService.getCustomerName() === 'kuslers' ||
          ConfigurationService.getCustomerName() === 'carlisle' ||
          ConfigurationService.getCustomerName() === 'testCustomerInventory'
      },

      {
        href: '/inventory-requests',
        displayName: 'Requests',
        imageInfo: {
          src: images['clipboard.png'],
          srcAlt: 'manage-requests'
        },
        hidden: !(
          ConfigurationService.getCustomerName() === 'keycompounding' ||
          ConfigurationService.getCustomerName() === 'testCustomerOrder' ||
          ConfigurationService.getCustomerName() === 'kuslers' ||
          ConfigurationService.getCustomerName() === 'testCustomerInventory'
        )
      },

      {
        href: '/manage-infrastructure',
        displayName: 'Configuration',
        imageInfo: {
          src: images['configure_icon.png'],
          srcAlt: 'manage-infrastructure'
        },
        hidden:
          ConfigurationService.getCustomerName() === 'kuslers' ||
          ConfigurationService.getCustomerName() === 'testCustomerInventory'
      },

      {
        href: '/users',
        displayName: 'Users',
        imageInfo: {
          src: images['users_icon.png'],
          srcAlt: 'manage-users'
        },
        hidden: !isSuperAdmin
      }
    ];

    return (
      <Fragment>
        <div className="homeimg img-container">
          <img src={getHomePageImage()} alt="home" className="home-img" />
          <h4 className="topleft">{`Hello ${username},`}</h4>
          <img src={getCustomerLogo()} alt="logo" id="topright" />
        </div>

        <Card className="content">
          <CardBody className="content-container">
            <CardTitle id="title">Your Apps</CardTitle>
            <div className="app-containers">
              {apps.map(app => {
                if (app.hidden) {
                  return undefined;
                }

                return (
                  <AppComponent
                    disabled={app.disabled}
                    key={app.displayName}
                    href={app.href}
                    imageInfo={app.imageInfo}
                    displayName={app.displayName}
                  />
                );
              })}
            </div>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default HomePage;
