import React, { Component } from 'react';
import EditableTableComponent from '../../components/editable-table/EditableTableComponent';
import ObjectTypeService from 'services/ObjectTypeService';
import RestService from '../../services/RestService';

function parseConditionData(ruleCondition) {
  const tags = ruleCondition.tags;

  let ruleId = ruleCondition.rule_id;
  let conditionId = ruleCondition.rule_condition_id;
  let itemId = tags.object_type_id;
  let thresholdValue = tags.threshold_value;
  let name = tags.object_name;

  return {
    ruleId: ruleId,
    id: conditionId, //this is for component to keep track
    conditionId: conditionId,
    itemId: itemId,
    threshold: thresholdValue,
    name: name
  };
}

async function fetchConditions(ruleId) {
  let conditions = await RestService.get(`/rules/${ruleId}/conditions`);
  let conditionInfoList = conditions.map(condition => parseConditionData(condition));

  return conditionInfoList;
}

function buildUpdateRuleConditionPayloadFromRequest(requestPayload) {
  let { threshold, name, itemId } = { ...requestPayload };

  return [
    {
      op: 'replace',
      path: '/conditions',
      value: [
        {
          property: 'object_type_id',
          op: '=',
          value: `${itemId}`
        },
        {
          property: 'value',
          op: '<=',
          value: parseInt(threshold, 10)
        }
      ]
      // value: parseInt(threshold, 10)
    },
    {
      op: 'replace',
      path: '/tags',
      value: {
        object_type_id: itemId,
        object_name: name,
        threshold_value: threshold,
        target: 'object_type'
      }
    }
  ];
}

function buildCreateRuleConditionPayloadFromRequest(requestPayload) {
  let { itemId, name, threshold } = { ...requestPayload };

  return {
    rule_condition_name: `low-stock-${itemId}`,
    enabled: true,
    tags: {
      object_type_id: itemId,
      object_name: name,
      threshold_value: threshold,
      target: 'object_type'
    },
    conditions: [
      {
        property: 'object_type_id',
        op: '=',
        value: `${itemId}`
      },
      {
        property: 'value',
        op: '<=',
        value: parseInt(threshold, 10)
      }
    ]
  };
}

export default class LowStockRuleContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ruleId: props.ruleId
    };

    this.handleRuleConditionRequests = this.handleRuleConditionRequests.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleAutoFill = this.handleAutoFill.bind(this);
  }

  componentDidMount() {
    ObjectTypeService.build().then(build => {
      fetchConditions(this.state.ruleId).then(result => {
        if (!result) {
          result = [];
        }

        const allItems = build.resourceArray;
        this.setState({
          conditions: result,
          allItems: allItems
        });
      });
    });
  }

  handleValidation(payloads) {
    let thresholdValue;
    let nameValue;
    let currentRowId;
    const inputErrorMap = {};

    let validatedObject = {
      errorExists: false,
      errorMap: inputErrorMap
    };

    payloads.forEach(function(payloadItem) {
      let inputError = {
        name: false,
        threshold: false
      };
      if (payloadItem._event !== 'pending_delete') {
        thresholdValue = payloadItem.threshold;
        nameValue = payloadItem.name;
        currentRowId = payloadItem.id;

        /**
         * (+thresholdValue === +thresholdValue) is called a 'self check' that is used to check if it is a valid number
         * '+variable' converts the value into a number.
         */

        // eslint-disable-next-line no-self-compare
        if (
          thresholdValue === '' ||
          thresholdValue === null ||
          !(+thresholdValue === +thresholdValue) ||
          +thresholdValue < 0
        ) {
          inputError['threshold'] = true;
        } else {
          inputError['threshold'] = false;
        }

        if (nameValue === '' || nameValue === null) {
          inputError['name'] = true;
        } else {
          inputError['name'] = false;
        }

        inputErrorMap[currentRowId] = inputError;
      }
    });

    const BreakForEachLoop = { exception: 'Error exists.' };

    try {
      Object.keys(inputErrorMap).forEach(function(key) {
        if (inputErrorMap[key].threshold || inputErrorMap[key].name) {
          validatedObject.errorExists = true;
          throw BreakForEachLoop;
        } else {
          validatedObject.errorExists = false;
        }
      });
    } catch (e) {
      alert('Please enter valid values in the highlighted field(s).');
      if (e !== BreakForEachLoop) throw e;
    }

    validatedObject.errorMap = inputErrorMap;

    return validatedObject;
  }

  handleAutoFill(headerId, newValue, data) {
    let allItems = this.state.allItems;

    let autoFillObject = {
      data: data,
      changed: true
    };

    if (headerId === 'name') {
      let itemIndex = allItems
        .map(function(i) {
          return i.objectTypeName;
        })
        .indexOf(newValue);
      let itemId = allItems[itemIndex].objectTypeId;
      data['itemId'] = itemId;
    }
    autoFillObject.data = data;

    if (data.name === '' && data.threshold === '') {
      autoFillObject.changed = false;
    }

    return autoFillObject;
  }

  async handleRuleConditionRequests(requests) {
    if (!requests || requests.length === 0) {
      return;
    }

    for (let index in requests) {
      let request = requests[index];

      let event = request._event;
      let conditionId = request.conditionId;
      let payload;
      switch (event) {
        case 'pending_new':
          payload = buildCreateRuleConditionPayloadFromRequest(request);
          await RestService.post(`/rules/${this.state.ruleId}/conditions`, payload);
          break;
        case 'pending_update':
          payload = buildUpdateRuleConditionPayloadFromRequest(request);
          await RestService.patch(`/rules/${this.state.ruleId}/conditions/${conditionId}`, payload);
          break;
        case 'pending_delete':
          await RestService.delete(`/rules/${this.state.ruleId}/conditions/${conditionId}`);
          break;
        default:
          console.log(`Unsupported Event[Name:${event}]`);
          break;
      }

      console.log(`==> Request: ${JSON.stringify(request)}`);
    }

    let conditions = await fetchConditions(this.state.ruleId);
    this.setState({ conditions: conditions });
    return conditions.map(condition => condition.id);
  }

  render() {
    let allItems = this.state.allItems || [];
    let dropDownOptions = allItems;
    let itemsWithoutLowStockRules;
    let itemsWithLowStockRules = this.state.conditions;

    if (itemsWithLowStockRules) {
      itemsWithLowStockRules = itemsWithLowStockRules.map(item => item.name);
      itemsWithoutLowStockRules = allItems.filter(
        item => itemsWithLowStockRules.indexOf(item.objectTypeName) === -1
      );
      dropDownOptions = itemsWithoutLowStockRules;
    }

    let headers = [
      {
        displayName: 'Item',
        id: 'name',
        cell: {
          input: 'dropdown',
          data: dropDownOptions,
          display: 'text',
          modifiable: false // whether it can be edited after being added
        }
      },
      {
        displayName: 'Stock Threshold',
        id: 'threshold',
        cell: {
          input: 'text',
          display: 'text',
          modifiable: true // whether it can be edited after being added
        }
      }
    ];

    return (
      <div>
        <div>
          <p className="tabbedSectionComponent-contentTitle">Low Stock</p>
          <p style={{ color: '#343434' }}>
            When the stock of an item drops below a set threshold, a notification will be sent.
          </p>
        </div>
        <div className="note">
          <span>Receive notifications for the items listed:</span>
        </div>
        <EditableTableComponent
          headers={headers}
          dataList={this.state.conditions}
          handleChangesFn={this.handleRuleConditionRequests}
          handleValidationFn={this.handleValidation}
          handleAutoFillFn={this.handleAutoFill}
        />
      </div>
    );
  }
}
