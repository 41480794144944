import React, { Component } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

import './DepartmentsOrdersGraph.css';

export default class DepartmentsExpediteOrdersGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maxColumns: 7,
      width: window.innerWidth
    };
  }

  render() {
    const { width } = this.state;
    const isMobile = width <= 768;

    const getResponsiveContainerAspect = () => {
      if (!isMobile) {
        return 4 / 2;
      }

      return 2.5 / 2;
    };

    return (
      <ResponsiveContainer aspect={getResponsiveContainerAspect()} width="100%">
        <BarChart
          data={this.props.departmentOrders.slice(0, this.state.maxColumns)}
          height={200}
          margin={{ top: 5, right: 10, bottom: 5, left: -35 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="simpleName" name="departmentName" />
          <YAxis />
          <Tooltip labelFormatter={name => this.props.departmentNameMap[name]} />
          <Legend margin={{ top: 5, right: 0, bottom: 5, left: 10 }} />
          <Bar
            dataKey="numberOfExpeditedOrders"
            stackId="a"
            fill="#FFA500"
            name="Expedited Orders"
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
