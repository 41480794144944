import React, { Component } from 'react';
import 'isomorphic-fetch'; // IE11 Compatibility Import for AuthService
import './LoginPage.css';
import logo from 'logo/White_with_Name.png';
import { Input } from 'mdbreact';
import ConfigurationService from '../../services/ConfigurationService';
import AuthService from '../../services/AuthService';
import ErrorMessageHelper from '../../error/ErrorMessageHelper';

function configureAuthService(customerId) {
  ConfigurationService.registerCustomerId(customerId);
  const config = ConfigurationService.getAuthConfiguration();
  AuthService.configure(config);
}

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionType: 'login',
      accountId: 'hu',
      username: '',
      password: '',
      userInfo: undefined,
      newPassword: '',
      confirmNewPassword: '',
      forgotPasswordAccountId: '',
      forgotPasswordUsername: '',
      forgotPasswordVerificationCode: ''
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  isValidForm = () => {
    return (
      this.state.username.length > 0 &&
      this.state.password.length > 0 &&
      this.state.accountId.length > 0
    );
  };

  isValidPasswordForm = () => {
    return (
      this.state.newPassword.length > 0 && this.state.newPassword === this.state.confirmNewPassword
    );
  };

  isValidForgotPasswordForm = () => {
    return (
      this.state.forgotPasswordUsername.length > 0 && this.state.forgotPasswordAccountId.length > 0
    );
  };

  isValidForgotPasswordVerificationForm = () => {
    return (
      this.state.forgotPasswordVerificationCode.length > 0 &&
      this.state.newPassword.length > 0 &&
      this.state.newPassword === this.state.confirmNewPassword
    );
  };

  handleSubmit = async event => {
    event.preventDefault();

    try {
      const customerId = 'hu';
      configureAuthService(customerId);
      console.log('Pre-AuthService check');
      const userInfo = await AuthService.login(
        customerId,
        this.state.username.toLowerCase(),
        this.state.password
      );
      this.setState({ userInfo });

      if (!userInfo.passwordChangeNeeded) {
        let referrer = '/';
        if (this.props.location.state && this.props.location.state.referrer) {
          referrer = this.props.location.state.referrer;
        }
        window.location = referrer;
      } else {
        this.setState({ actionType: 'newPassword' });
      }
    } catch (err) {
      let customErr = {};
      const input = {
        accountId: this.state.accountId,
        username: this.state.username
      };

      customErr = ErrorMessageHelper.getErrorMessage(err, input);

      alert(customErr.message);
      console.error(customErr.message);
    }
  };

  handleSubmitNewPassword = async event => {
    event.preventDefault();

    AuthService.handleNewPasswordChallenge(this.state.newPassword).then(
      () => {
        window.location = '/';
      },
      err => {
        alert(`Oops! ${err.message}`);
      }
    );
  };

  handleForgotPassword = async event => {
    event.preventDefault();

    console.log('Forgot password flow begins!');
    this.setState({ actionType: 'forgotPassword' });
  };

  handleSubmitForgotPasswordUsername = async event => {
    try {
      event.preventDefault();

      console.log('Forgot password flow continues!');

      const customerId = 'hu';
      configureAuthService(customerId);

      const username = this.state.forgotPasswordUsername.toLowerCase();
      const context = this;
      await AuthService.forgotPassword(username).then(_ => {
        context.setState({ actionType: 'forgotPasswordVerification' });
      });
    } catch (err) {
      const input = {
        accountId: this.state.forgotPasswordAccountId,
        username: this.state.forgotPasswordUsername
      };
      const customErr = ErrorMessageHelper.getErrorMessage(err, input);

      alert(customErr.message);
    }
  };

  handleSubmitForgotPasswordVerification = async event => {
    event.preventDefault();

    console.log('Forgot password flow finishes!');
    const username = this.state.forgotPasswordUsername.toLowerCase();
    const verificationOde = this.state.forgotPasswordVerificationCode;
    const newPassword = this.state.newPassword;

    const context = this;
    await AuthService.forgotPasswordSubmit(username, verificationOde, newPassword).then(_ => {
      context.setState({ actionType: 'login' });
    });
  };

  renderNewPassword() {
    return (
      <div className="content-body login-page-body">
        <div className="container login-form" style={{ textAlign: 'initial' }}>
          <form className="login-form-background">
            <img src={logo} className="brand-logo" alt="brand-logo" />

            <div className="form-group">
              <p
                style={{
                  textAlign: 'center',
                  color: 'white'
                }}
              >
                {`Welcome ${this.state.username}! Please enter a new password.`}
              </p>
              <Input
                className="input-default"
                label="New Password"
                id="newPassword"
                onInput={this.handleChange}
                type="password"
              />
              <Input
                className="input-default"
                label="Confirm New Password"
                id="confirmNewPassword"
                onInput={this.handleChange}
                type="password"
              />
            </div>

            <button
              disabled={!this.isValidPasswordForm()}
              type="submit"
              onClick={this.handleSubmitNewPassword}
              className="add-user-button"
            >
              Change Password
            </button>
          </form>
        </div>
      </div>
    );
  }

  renderForgotPasswordVerification = () => {
    return (
      <div className="content-body login-page-body">
        <div className="container login-form" style={{ textAlign: 'initial' }}>
          <form className="login-form-background">
            <img src={logo} className="brand-logo" alt="brand-logo" />

            <div className="form-group">
              <p
                style={{
                  textAlign: 'center',
                  color: 'white'
                }}
              >
                {
                  'Check your email and Please enter the verification code you received along with a new password.'
                }
              </p>
              <Input
                className="input-default"
                label="Verification Code"
                value={this.state.forgotPasswordVerificationCode}
                id="forgotPasswordVerificationCode"
                onInput={this.handleChange}
              />
              <Input
                className="input-default"
                label="New Password"
                value={this.state.newPassword}
                id="newPassword"
                onInput={this.handleChange}
                type="password"
              />
              <Input
                className="input-default"
                label="Confirm New Password"
                id="confirmNewPassword"
                onInput={this.handleChange}
                type="password"
              />
            </div>

            <div className="profile-form-buttons">
              <button
                disabled={!this.isValidForgotPasswordVerificationForm()}
                type="submit"
                onClick={this.handleSubmitForgotPasswordVerification}
                className="default-button"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  renderForgotPassword = () => {
    return (
      <div className="content-body login-page-body">
        <div className="container login-form" style={{ textAlign: 'initial' }}>
          <form className="login-form-background">
            <img src={logo} className="brand-logo" alt="brand-logo" />

            <div className="form-group">
              <p
                style={{
                  textAlign: 'center',
                  color: 'white'
                }}
              >
                {
                  'Please enter your Username. A temporary password will be sent to your registered email address.'
                }
              </p>
              <Input
                className="input-default"
                label="Username"
                id="forgotPasswordUsername"
                onInput={this.handleChange}
              />
            </div>
            <div className="profile-form-buttons">
              <button
                className="cancel-button change-password-button"
                type="button"
                onClick={() => {
                  this.setState({ actionType: 'login', hasError: false });
                }}
              >
                Cancel
              </button>
              <button
                disabled={!this.isValidForgotPasswordForm()}
                type="submit"
                onClick={this.handleSubmitForgotPasswordUsername}
                className="default-button  change-password-button"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  renderLogin = () => {
    return (
      <div className="content-body login-page-body">
        <div className="container login-form" style={{ textAlign: 'initial' }}>
          <form className="login-form-background">
            <img src={logo} className="brand-logo" alt="brand-logo" />

            <div className="form-group">
              <Input
                className="input-default"
                label="Username"
                id="username"
                onInput={this.handleChange}
              />
            </div>

            <div className="form-group">
              <Input
                className="input-default"
                label="Password"
                id="password"
                onInput={this.handleChange}
                type="password"
              />
            </div>

            <button
              disabled={!this.isValidForm()}
              type="submit"
              onClick={this.handleSubmit}
              className="add-user-button"
            >
              Log In
            </button>

            <div className="center-align">
              <button onClick={this.handleForgotPassword} className="asText">
                {' '}
                Forgot Password?
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  render() {
    if (this.state.actionType === 'newPassword') {
      return this.renderNewPassword();
    }
    if (this.state.actionType === 'forgotPassword') {
      return this.renderForgotPassword();
    }
    if (this.state.actionType === 'forgotPasswordVerification') {
      return this.renderForgotPasswordVerification();
    }
    return this.renderLogin();
  }
}

export default LoginPage;
