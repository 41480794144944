import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import NavbarHeader from '../pages/header/NavbarHeader';
import AuthService from '../services/AuthService';

import './AuthorizedProcessAppRoute.css';

const AuthorizedProcessAppRoute = ({
  component: Component,
  hide_header_searchBar,
  path,
  authorizedRoles
}) => {
  const sessionInfo = AuthService.getSessionInfo();
  const loggedIn = !!sessionInfo;

  if (loggedIn) {
    // Make sure fcWidget.init is included before setting these values
    // To set unique user id in your system when it is available
    //
    // 	<!-- Initializing Chat Widget -->
    window.fcWidget.init({
      token: '8b71db32-33e9-4423-916d-966821e989a0',
      host: 'https://wchat.freshchat.com',
      externalId: sessionInfo.username
    });
    window.fcWidget.user.get(function(resp) {
      const status = resp && resp.status;
      // Status 200 is from Freschat API docs -- It means that there is a user registered using the chat
      if (status !== 200) {
        window.fcWidget.user.setProperties({
          firstName: sessionInfo.attributes.given_name, // user's first name
          lastName: sessionInfo.attributes.family_name, // user's last name
          email: sessionInfo.attributes.email, // user's email address
          phone: sessionInfo.attributes.phone_number
        });
        window.fcWidget.on('user:created', function(resp) {
          const status = resp && resp.status;
          // TODO: this part of initalization will check if the user exists and if so, we use restoreID to initialize
          // chat with previous conversation if logging on to the web app via different machines
          if (status === 200) {
            // Update restoreId in your database
          }
        });
      }
    });
  }
  return (
    <Route
      render={matchProps => {
        // redirect to login page if not logged in
        if (!loggedIn) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { referrer: path }
              }}
            />
          );
        }

        // redirect to error page if not authorized
        if (authorizedRoles) {
          let authorized = false;

          for (const index in sessionInfo.userGroups) {
            const group = sessionInfo.userGroups[index];
            if (authorizedRoles.includes(group)) {
              authorized = true;
              break;
            }
          }

          if (!authorized) {
            return (
              <Redirect
                to={{
                  pathname: '/error/authorized',
                  state: { referrer: path }
                }}
              />
            );
          }
        }

        return (
          <Fragment>
            <NavbarHeader hide_bar={hide_header_searchBar} />
            <div>
              <Component {...matchProps} />
            </div>
          </Fragment>
        );
      }}
    />
  );
};

export default AuthorizedProcessAppRoute;
