export default class LineGraphDataEntry {
  constructor(timestamp) {
    let formattedTimestamp = timestamp;
    if (typeof timestamp === 'number') {
      formattedTimestamp = new Date(timestamp).toLocaleString();
    }

    this.timestamp = formattedTimestamp;
    this.timeFrame = timestamp;
    this.data = {
      timestamp: formattedTimestamp
    };
  }

  addDataPoint(dataKey, dataValue) {
    this.data[dataKey] = dataValue;
  }
}
