import React, { Component } from 'react';
import { Card, CardBody } from 'mdbreact';
import './SummaryTile.css';
import green_circle from 'img/green_circle.png';
import red_circle from 'img/red_circle.png';
import yellow_circle from 'img/yellow_circle.png';
import blue_circle from 'img/blue_circle.png';
import purple_circle from 'img/purple_circle.png';
import grey_circle from 'img/grey_circle.png';

const tileBorderStyleMap = {
  ETA: 'summary-tile-border-yellow',
  Arrived: 'summary-tile-border-green',
  'New Requests': 'summary-tile-border-blue',
  Ordered: 'summary-tile-border-purple',
  Backorder: 'summary-tile-border-red',
  'Total Requests': 'summary-tile-border-grey'
};

const tileBackgroundStyleMap = {
  ETA: 'summary-tile-background-yellow',
  Arrived: 'summary-tile-background-green',
  'New Requests': 'summary-tile-background-blue',
  Ordered: 'summary-tile-background-purple',
  Backorder: 'summary-tile-background-red',
  'Total Requests': 'summary-tile-background-grey'
};

const indicatorImageMap = {
  ETA: yellow_circle,
  Arrived: green_circle,
  'New Requests': blue_circle,
  Ordered: purple_circle,
  Backorder: red_circle,
  'Total Requests': grey_circle
};

export default class SummaryTile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      header: this.props.header,
      tileData: this.props.data,
      filterEnabled: this.props.filterEnabled
    };

    this.handleClick = this.handleClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      tileData: newProps.data,
      filterEnabled: newProps.filterEnabled
    });
  }

  getTileBorderColor(tile_title) {
    return tileBorderStyleMap[tile_title] || 'whiteCell';
  }

  getTileTitleColor(tile_title) {
    return tileBackgroundStyleMap[tile_title] || 'whiteCell';
  }

  getImageSource(tile_title) {
    return indicatorImageMap[tile_title];
  }

  handleClick() {
    // to handle filter view
    this.props.callbackFn(this.state.tileData.status);
  }

  render() {
    return (
      <Card
        title="Click to view orders in this department."
        className={`summary-tile ${this.getTileBorderColor(this.state.tileData.title)} ${
          this.state.filterEnabled ? null : 'filter-disabled'
        }`}
        onClick={() => this.handleClick()}
      >
        <CardBody className="summary-tile-body">
          <div
            className={`summary-tile-title ${this.getTileTitleColor(this.state.tileData.title)}`}
          >
            <img
              className="dept-indicator-image"
              src={this.getImageSource(this.state.tileData.title)}
              alt="Status"
            />{' '}
            {this.state.tileData.title}
          </div>
          <div className="summary-tile-content">
            <div className="summary-tile-left-column">
              <div className="left-column-number">{this.state.tileData.totalOrders}</div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}
