import React, { Component, Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import { Card, CardBody, Input } from 'mdbreact';
import Calendar from 'react-calendar';
import ClearIcon from '@material-ui/icons/Clear';
import ConfigurationService from '../../services/ConfigurationService';
import RestService from '../../services/RestService';
import { getFormattedDate } from '../../common/Utilities';
import './AddNewTrackedObject.css';
import MoreImage from '../../img/More.png';

export default class AddNewTrackedObject extends Component {
  constructor(props) {
    super(props);
    const solutionType = ConfigurationService.getSolutionType();
    this.state = {
      label: {
        rfid: 'RFID Tag Number',
        id: solutionType === 'asset' ? 'Asset ID' : 'Order Number',
        part: solutionType === 'asset' ? null : 'Part Number',
        date: solutionType === 'asset' ? 'Expiration Date' : 'Due Date'
      },
      touched: {
        rfid: false,
        id: false
      },
      errorMessage: {
        rfid: ' Please enter an RFID Tag Number',
        id: solutionType === 'asset' ? ' Please enter an Asset ID' : ' Please enter an Order Number'
      },
      showBanner: '',
      rfid: '',
      id: '',
      part: '',
      date: '',
      solutionType,
      clickedCreate: false
    };
  }
  componentDidMount() {
    this.onLoad();
  }
  async onLoad() {
    const trackers = await RestService.get('/trackers');
    const plants = await RestService.get('/plants');
    const plant_id = plants[0].plant_id;
    await this.setState({ trackers, plant_id });
  }
  changeDate = date => {
    this.setState({ date, showCalendar: false });
  };
  renderDate = attrName => {
    return (
      <Fragment>
        <Calendar onChange={this.changeDate} value={this.state[attrName]} className="calendar" />
      </Fragment>
    );
  };
  handleChange = (id, value) => {
    this.setState({
      touched: {
        ...this.state.touched,
        [id]: false
      },
      [id]: value,
      clickedCreate: false
    });
  };
  renderClearButton = attrName => {
    return (
      <ClearIcon
        className="clear-button"
        onClick={() => {
          this.setState({ [attrName]: '' });
        }}
      />
    );
  };
  renderErrorMessage = attrName => {
    const { touched, errorMessage, clickedCreate } = this.state;
    if (touched[attrName] || (clickedCreate && !this.state[attrName])) {
      return <span className="optional-text-error">{` -${errorMessage[attrName]}`}</span>;
    }
  };
  renderInput = (attrName, optional) => {
    const { label, showCalendar } = this.state;
    const attrValue = this.state[attrName];
    return (
      <div>
        <div className="group-input-area-button">
          <Input
            disabled={false}
            value={attrName === 'date' ? attrValue && getFormattedDate(attrValue, 'LL') : attrValue}
            className="input-area"
            label={
              <span>
                {`${label[attrName]} `}
                {optional ? <span className="optional-text">- optional</span> : null}
                {attrName !== 'date' && attrName !== 'part' && this.renderErrorMessage(attrName)}
              </span>
            }
            labelClass="active-label"
            id={attrName}
            onInput={
              attrName !== 'date'
                ? event => this.handleChange(event.target.id, event.target.value)
                : null
            }
            onFocus={() => {
              if (attrName === 'date') {
                this.setState({ showCalendar: true });
              } else {
                this.setState({
                  showCalendar: false
                });
              }
            }}
            onBlur={() => {
              if (attrName !== 'date' && !attrValue) {
                this.setState({
                  touched: {
                    ...this.state.touched,
                    [attrName]: true
                  }
                });
              }
            }}
          />
          {attrValue && this.renderClearButton(attrName)}
        </div>
        {attrName === 'date' && showCalendar && this.renderDate(attrName)}
      </div>
    );
  };
  handleDismiss = () => {
    this.setState({ showBanner: '' });
  };
  createTrackedObject = async () => {
    this.setState({ showCalendar: false, clickedCreate: true });
    const { trackers, rfid, id, date, plant_id, label, part } = this.state;
    if (!rfid || !id) {
      this.setState({
        showBanner: 'Please fill in all required fields and try again!',
        isSuccess: false
      });
      return;
    }
    const code = rfid.trim();
    const trackerExists = trackers.find(x => x.tracker_serial === code.replace(/^0+/, ''));
    if (!trackerExists) {
      this.setState({ showBanner: 'Tag not found', isSuccess: false });
      return;
    }
    const payload = {
      object_name: id.trim(),
      plant_id,
      tracker_id: trackerExists.tracker_id,
      object_class: 'order',
      tags: { miss_found_status: false }
    };
    const addZone =
      ConfigurationService.getFullConfigurations().locationInfo &&
      ConfigurationService.getFullConfigurations().locationInfo.ADD_ZONE;
    if (addZone) {
      payload.add_zone = addZone;
    }
    if (part) {
      payload.part_id = part.trim();
    }
    if (date) {
      const newDate = new Date(date);
      payload.due_date = newDate.getTime();
    }
    await RestService.post('/tracked-objects', payload).then(
      result => {
        this.setState({ showBanner: 'Successfully associated!', isSuccess: true });
        this.setState({
          rfid: '',
          id: '',
          date: '',
          tracker_id: '',
          clickedCreate: false,
          part: ''
        });
      },
      err => {
        this.setState({
          showBanner: `${label.id} / RFID Tag is already associated`,
          isSuccess: false,
          clickedCreate: false
        });
      }
    );
  };
  displayBanner = () => {
    const { showBanner, isSuccess } = this.state;
    return (
      <Alert
        className="add-user-alerts"
        bsStyle={isSuccess ? 'success' : 'danger'}
        onDismiss={this.handleDismiss}
      >
        <h4>{isSuccess ? 'Success!' : 'Try Again!'}</h4>
        <p>{showBanner}</p>
      </Alert>
    );
  };
  render() {
    const { solutionType, showBanner } = this.state;
    return (
      <Fragment>
        <Card className="content_holder">
          {showBanner && this.displayBanner()}
          <div className="back-button">
            <a href="/tracked-objects" style={{ color: '#1e2120' }}>
              <img src={MoreImage} alt="more" className="image-style" />
              {` Back`}
            </a>
          </div>
          <Card className="add-user">
            <div className="users-title">
              {solutionType === 'asset' ? 'Add Asset' : 'Add Order'}
            </div>
            <CardBody>
              <div className="profile-fields">
                {this.renderInput('rfid')}
                {this.renderInput('id')}
                {solutionType === 'asset' ? null : this.renderInput('part', true)}
                {this.renderInput('date', true)}
              </div>
              <button className="add-user-button" onClick={this.createTrackedObject}>
                Create
              </button>
            </CardBody>
          </Card>
        </Card>
      </Fragment>
    );
  }
}
