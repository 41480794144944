import React from 'react';
import RestService from '../../../services/RestService';
import ResourceProvider from './ResourceProvider';
import _ from 'lodash';

const resourceProviderName = 'Department';
const parentName = 'Plant';

export default class DepartmentResource extends ResourceProvider {
  static async build() {
    let getDepartments = async () => {
      let departments = await RestService.get('/departments');

      let departmentList = _.map(departments, department => {
        let departmentInfo = department.department_info;
        let departmentName = department.department_name;
        let departmentId = department.department_id;
        let plantId = department.plant_id;

        return {
          thisDesc: departmentInfo,
          thisName: departmentName,
          thisId: departmentId,
          plantId: plantId
        };
      });

      return departmentList;
    };

    let departments = await getDepartments();

    return new DepartmentResource(
      [...departments.sort((a, b) => a.thisName.localeCompare(b.thisName))],
      resourceProviderName
    );
  }

  deleteItem(thisItem, handleCloseFunc, showDeleteErrorFunc) {
    RestService.delete('/departments/' + thisItem.thisId).then(
      result => {
        this.resourceArray = this.resourceArray.filter(function(department) {
          return department !== thisItem;
        });
        handleCloseFunc();
      },
      err => {
        showDeleteErrorFunc();
      }
    );
  }

  addItemsSchema(index, item, changeValuesFunc, resourceProviders, nameCheck, parentIdCheck) {
    return (
      <div>
        {super.parentIdForm(
          index,
          item.parentId,
          resourceProviders.find(provider => provider.getName() === parentName),
          changeValuesFunc,
          parentIdCheck
        )}
        {super.levelNameForm(index, item.name, changeValuesFunc, nameCheck)}
        {super.descriptionForm(index, item.description, changeValuesFunc)}
      </div>
    );
  }

  editItemsSchema(
    index,
    itemToEdit,
    changeValuesFunc,
    resourceProviders,
    nameCheck,
    parentIdCheck
  ) {
    let name = resourceProviders
      .find(provider => provider.getName() === parentName)
      .resourceArray.filter(obj => {
        return obj.thisId === itemToEdit.plantId;
      })[0].thisName;
    return (
      <div>
        {super.editParentIdForm(
          name,
          resourceProviders.find(provider => provider.getName() === parentName)
        )}
        {super.editLevelNameForm(index, itemToEdit, changeValuesFunc, nameCheck)}
        {super.editDescriptionForm(index, itemToEdit, changeValuesFunc)}
      </div>
    );
  }

  async editItem(
    formCheckFunc,
    updateErrorsFunc,
    stagedItems,
    resourceProviders,
    customerId,
    itemToEdit
  ) {
    let errorCollection = [];
    let successCollection = [];
    let successRegistry = [];

    var payload = [];

    if (stagedItems[0].name !== '') {
      payload.push({
        op: 'replace',
        path: '/department_name',
        value: stagedItems[0].name
      });
    }

    if (stagedItems[0].description !== '') {
      payload.push({
        op: 'replace',
        path: '/department_info',
        value: stagedItems[0].description
      });
    }

    if (payload.length >= 1) {
      await RestService.patch('/departments/' + itemToEdit.thisId, payload).then(
        result => {
          let updateItem = this.getArrayItemById(result.department_id);

          updateItem.thisName = result.department_name;
          updateItem.thisDesc = result.department_info;

          successCollection.push(result.department_name);
          successRegistry.push(0);
        },
        err => {
          errorCollection.push({
            entry: stagedItems[0].name,
            errMessage: err.response.data.message
          });
        }
      );
    }
    updateErrorsFunc(successRegistry, successCollection, errorCollection);
    return true;
  }

  async addItem(formCheckFunc, updateErrorsFunc, stagedItems) {
    let errorCollection = [];
    let successCollection = [];
    let successRegistry = [];

    let nameChecks = formCheckFunc('name');
    let parentCheck = formCheckFunc('parentId');

    if (!nameChecks || !parentCheck) {
      return false;
    }

    for (const index of stagedItems.keys()) {
      let payload = {
        department_name: stagedItems[index].name,
        plant_id: stagedItems[index].parentId
      };

      if (stagedItems[index].description !== '') {
        payload.department_info = stagedItems[index].description;
      }

      await RestService.post('/departments', payload).then(
        result => {
          this.add({
            thisDesc: result.department_info,
            thisName: result.department_name,
            thisId: result.department_id,
            plantId: result.plant_id
          });

          successCollection.push(result.department_name);
          successRegistry.push(index);
        },
        err => {
          errorCollection.push({
            entry: stagedItems[index].name,
            errMessage: err.response.data.message
          });
        }
      );
    }

    updateErrorsFunc(successRegistry, successCollection, errorCollection);
    return true;
  }
}
