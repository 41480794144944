import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Style from './css/Overview.module.css';

const OverviewHeader = ({ overviewDataLabel1, overviewDataLabel2, overviewDataLabel3 }) => {
  return (
    <Fragment>
      <th className={`${Style.header_text}`}>{overviewDataLabel1}</th>
      <th className={`${Style.header_text}`}>{overviewDataLabel2}</th>
      <th className={`${Style.header_text}`}>{overviewDataLabel3}</th>
    </Fragment>
  );
};

OverviewHeader.propTypes = {
  overviewDataLabel1: PropTypes.string.isRequired,
  overviewDataLabel2: PropTypes.string.isRequired,
  overviewDataLabel3: PropTypes.string.isRequired
};

const OverviewBody = ({ overviewData1, overviewData2, overviewData3 }) => {
  return (
    <Fragment>
      <td className={`${Style.body_text}`}>{overviewData1}</td>
      <td className={`${Style.body_text} ${overviewData2 > 0 ? Style.status_color : ''}`}>
        {overviewData2}
      </td>
      <td className={`${Style.body_text}`}>{overviewData3}</td>
    </Fragment>
  );
};

OverviewBody.propTypes = {
  overviewData1: PropTypes.number.isRequired,
  overviewData2: PropTypes.number.isRequired,
  overviewData3: PropTypes.number.isRequired
};

const WeeklyStatsHeader = ({ weeklyStatsLabel1, weeklyStatsLabel2, weeklyStatsLabel3 }) => {
  return (
    <Fragment>
      <th className={`${Style.header_text}`}>{weeklyStatsLabel1}</th>
      <th className={`${Style.header_text}`}>{weeklyStatsLabel2}</th>
      <th className={`${Style.header_text}`}>{weeklyStatsLabel3}</th>
    </Fragment>
  );
};

WeeklyStatsHeader.propTypes = {
  weeklyStatsLabel1: PropTypes.string,
  weeklyStatsLabel2: PropTypes.string.isRequired,
  weeklyStatsLabel3: PropTypes.string.isRequired
};

const WeeklyStatsBody = ({ weeklyStats1, weeklyStats2, weeklyStats3 }) => {
  return (
    <Fragment>
      <td className={`${Style.body_text}`}>{weeklyStats1}</td>
      <td className={`${Style.body_text}`}>{weeklyStats2}</td>
      <td className={`${Style.body_text}`}>{weeklyStats3}</td>
    </Fragment>
  );
};

WeeklyStatsBody.propTypes = {
  weeklyStats1: PropTypes.number,
  weeklyStats2: PropTypes.number.isRequired,
  weeklyStats3: PropTypes.number.isRequired
};

export default class Overview extends PureComponent {
  render() {
    const {
      overviewDataLabel1,
      overviewDataLabel2,
      overviewDataLabel3,
      overviewData1,
      overviewData2,
      overviewData3,
      weeklyStatsLabel1,
      weeklyStatsLabel2,
      weeklyStatsLabel3,
      weeklyStats1,
      weeklyStats2,
      weeklyStats3,
      title
    } = this.props;

    return (
      <Fragment>
        <p className={`${Style.table_title}`}>{title}</p>
        <table>
          <thead>
            <tr>
              <OverviewHeader
                className={`${Style.table_header}`}
                overviewDataLabel1={overviewDataLabel1}
                overviewDataLabel2={overviewDataLabel2}
                overviewDataLabel3={overviewDataLabel3}
              />
            </tr>
          </thead>
          <tbody>
            <tr>
              <OverviewBody
                className={`${Style.table_body}`}
                overviewData1={overviewData1}
                overviewData2={overviewData2}
                overviewData3={overviewData3}
              />
            </tr>
          </tbody>
        </table>
        <p className={`${Style.table_title}`}>Metrics</p>
        <table>
          <thead>
            <tr>
              <WeeklyStatsHeader
                className={`${Style.table_header}`}
                weeklyStatsLabel1={weeklyStatsLabel1}
                weeklyStatsLabel2={weeklyStatsLabel2}
                weeklyStatsLabel3={weeklyStatsLabel3}
              />
            </tr>
          </thead>
          <tbody>
            <tr>
              <WeeklyStatsBody
                className={`${Style.table_body}`}
                weeklyStats1={weeklyStats1}
                weeklyStats2={weeklyStats2}
                weeklyStats3={weeklyStats3}
              />
            </tr>
          </tbody>
        </table>
      </Fragment>
    );
  }
}

Overview.propTypes = {
  overviewDataLabel1: PropTypes.string.isRequired,
  overviewDataLabel2: PropTypes.string.isRequired,
  overviewDataLabel3: PropTypes.string.isRequired,
  overviewData1: PropTypes.number.isRequired,
  overviewData2: PropTypes.number.isRequired,
  overviewData3: PropTypes.number.isRequired,
  weeklyStatsLabel1: PropTypes.string,
  weeklyStatsLabel2: PropTypes.string.isRequired,
  weeklyStatsLabel3: PropTypes.string.isRequired,
  weeklyStats1: PropTypes.number,
  weeklyStats2: PropTypes.number.isRequired,
  weeklyStats3: PropTypes.number.isRequired
};
