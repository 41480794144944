import React from 'react';
import { FormText, Label, Input, FormGroup } from 'reactstrap';
import '../ManageInfrastructure.css';

export default class ResourceProvider {
  constructor(promiseParam, resourceName) {
    if (typeof promiseParam === 'undefined') {
      throw new Error('Cannot be called directly');
    }

    this.resourceName = resourceName;
    this.resourceArray = promiseParam;
  }

  getArray() {
    return this.resourceArray;
  }

  add(object) {
    this.resourceArray = [...this.resourceArray].concat([object]);
  }

  getName() {
    return this.resourceName;
  }

  getArrayItemById(itemId) {
    return this.resourceArray.find(item => item.thisId === itemId);
  }

  levelNameForm(index, name, changeValuesFunc, nameCheck) {
    return (
      <FormGroup>
        <Label for="levelName" className="forms-label">
          {this.resourceName}
          {this.resourceName === 'Gateway' ? ' #' : ' Name'}
{' '}
        </Label>
        <Input
          type="textArea"
          className={nameCheck && nameCheck[index] ? 'input-error' : 'form-name-input'}
          name="text"
          value={name}
          onChange={event => changeValuesFunc(event, 'name', index)}
        />
      </FormGroup>
    );
  }

  editLevelNameForm(index, itemToEdit, changeValuesFunc, nameCheck) {
    return (
      <FormGroup>
        <Label for="levelName" className="forms-label">
          {this.resourceName}
          {this.resourceName === 'Gateway' ? ' #' : ' Name'}
{' '}
        </Label>
        <Input
          type="textArea"
          className={nameCheck && nameCheck[index] ? 'input-error' : 'form-name-input'}
          name="text"
          disabled={this.resourceName === 'Gateway'}
          placeholder={itemToEdit.thisName}
          onChange={event => changeValuesFunc(event, 'name', index)}
        />
      </FormGroup>
    );
  }

  parentIdForm(index, parentId, parentProvider, changeValuesFunc, parentIdCheck) {
    return (
      <FormGroup>
        <Label for="parentId" className="forms-label">
          {`${parentProvider.getName()} Name`}
        </Label>
        <Input
          type="select"
          className={parentIdCheck && parentIdCheck[index] ? 'input-error' : 'form-parent-input'}
          name="select"
          value={parentId}
          onChange={event => changeValuesFunc(event, 'parentId', index)}
        >
          <option id="emptyOption" value="" />
          {parentProvider.getArray().map(parent => {
            return (
              <option key={parent.thisId} value={parent.thisId}>
                {parent.thisName}
              </option>
            );
          })}
        </Input>
      </FormGroup>
    );
  }

  editParentIdForm(parentName, parentProvider) {
    return (
      <FormGroup>
        <Label for="parentId" className="forms-label">
          {`${parentProvider.getName()} Name`}
        </Label>
        <Input disabled placeholder={parentName} value="" />
      </FormGroup>
    );
  }

  descriptionForm(index, description, changeValuesFunc) {
    return (
      <FormGroup>
        <Label for="plantDescription" className="forms-label">
          {`${this.resourceName} Description `}
          <FormText className="optionalText" color="muted">
            Optional form
          </FormText>
        </Label>
        <Input
          type="textArea"
          name="text"
          value={description}
          onChange={event => changeValuesFunc(event, 'description', index)}
        />
      </FormGroup>
    );
  }

  editDescriptionForm(index, itemToEdit, changeValuesFunc) {
    return (
      <FormGroup>
        <Label for="plantDescription" className="forms-label">
          {`${this.resourceName} Description `}
          <FormText className="optionalText" color="muted">
            Optional form
          </FormText>
        </Label>
        <Input
          type="textArea"
          name="text"
          placeholder={itemToEdit.thisDesc}
          onChange={event => changeValuesFunc(event, 'description', index)}
        />
      </FormGroup>
    );
  }

  optionalGatewayForm(index, number, changeValuesFunc) {
    return (
      <FormGroup>
        <Label for="gatewayNumber" className="forms-label">
          {'Gateway # '}
{' '}
          <FormText className="optionalText" color="muted">
            Optional form
          </FormText>
        </Label>
        <Input
          type="textArea"
          name="text"
          value={number}
          onChange={event => changeValuesFunc(event, 'optionalGatewayNumber', index)}
        />
      </FormGroup>
    );
  }

  imageForm(index, imageDetails, imageValuesFunc) {
    return (
      <div>
        <Label for="Image" className="forms-label">
          {'Image'}
        </Label>
{' '}
        <br />
        <Input type="file" onChange={event => imageValuesFunc(event, 'imageDetails', index)} />
      </div>
    );
  }

  textForm(index, schema, changeValuesFunc, validInput, placeholder = null) {
    return (
      <FormGroup key={schema.param_name}>
        <Label for="levelName" className="forms-label">
          {`${this.resourceName} ${schema.display_name}`}
{' '}
        </Label>
        <Input
          type="textArea"
          className={validInput ? 'form-name-input' : 'input-error'}
          name="text"
          placeholder={placeholder}
          onChange={event => changeValuesFunc(event, schema.param_name, index)}
        />
      </FormGroup>
    );
  }
}
